import styled, {createGlobalStyle} from 'styled-components';
import {Link} from "react-router-dom";


export const width = {
    largePhone: '400px',
    tablet: '768px',
    laptop: '1024px',
    desktop: '1920px',
};


export const device = {
    largePhone: `(min-width: ${width.largePhone})`,
    tablet: `(min-width: ${width.tablet})`,
    laptop: `(min-width: ${width.laptop})`,
    desktop: `(min-width: ${width.desktop})`,
};


export const GlobalStyle = createGlobalStyle`
    :root {
        --excursion-h1-color: ${props => props.theme.excursionTitleColor || ' #A28B6F'};
        --excursion-h2-color: ${props => props.theme.headerColor || ' #6B207D'};
        --action-btn-color: white;
        --action-btn-bg: #6B207D;
        --bg-grey-lighter: #F1ECE7;
        --bg-white: white;
        --bg-primary-light: #D0BFB0;
        --bg-primary-lighter: #F1ECE7;
        --bg-primary-lightest: #fcfcfc;
        --bg-smoke-darker: rgba(0, 0, 0, 0.85);
        --border-white: white;
        --border-primary: rgb(90, 90, 90);
        --border-primary-light: #D0BFB0;
        --border-primary-red: #d40e14;
        --text-grey-darkest: #3d4852;
        --text-grey-darker: #373441;
        --text-white: white;
        --text-primary-dark: ${props => props.theme.headerColor || ' #6B207D'};
        --text-primary: rgb(90, 90, 90);
        --text-primary-light: #D0BFB0;
        --text-primary-red: #d40e14;
        --filters-container-bg:${props => props.theme.headerColor || ' #6B207D'};
    }
    
    @font-face {
        font-family: 'MainFont';
        src: ${props => props.theme.fontFileSrc || "url(/fonts/Arial.ttf)"};
        ${props => props.theme.fontFileSrc2 && props.theme.fontFileSrc2};
    }
    @font-face {
        font-family: 'MainFont';
        src: ${props => props.theme.fontFileBoldSrc || props.theme.fontFileSrc || "url(/fonts/Arial.ttf)"};
        font-weight: bold;
    }
    @font-face {
      font-family: 'Heading';
      src: ${props => props.theme.headingFontFileSrc || props.theme.fontFileSrc || "url(/fonts/Arial.ttf)"};
    }
    
    body, h1, h2, h3, h4, h5, h6 {
        font-family: 'MainFont', sans-serif;
    }

    h1, h2, h3, h4, h5, h6 {
      color: ${props => props.theme.headingTextColor || props.theme.bodyColor || "black" };
      text-transform: ${ props => props.theme.headingTextTransform || "none" } ;
      font-family: 'Heading', sans-serif;
    }

    @media screen and (min-width: 40em) {
      h1 {
        font-size: 2.5rem;
        line-height: 1.2;
      } 
    }
    
    thead {
        background-color: ${props => props.theme.tableHeadingBackground || props.theme.subheaderBackground || "black"};
        color: ${props => props.theme.tableHeadingColor || props.theme.subheaderColor || "white"};
        font-weight: bold;
    }
    
    tbody tr {
        color: ${props => props.theme.tableColor || props.theme.bodyColor || "black"};
    }
    
    tbody tr:nth-child(odd) {
        background-color: ${props => props.theme.tableBackgroundTwo || "white"};
    }    
    
    tbody tr:nth-child(even) {
        background-color: ${props => props.theme.tableBackgroundOne || "#fafafa"};
    }
    
    tbody th, thead th, tbody td {
        font-size: 0.8rem;
        padding: 0.2rem;
        word-wrap: break-word;
    }
    
    a, a:hover, a:focus {
        color: ${props => props.theme.defaultLinkColor || "#1779ba"};
        text-decoration: underline;
    }
    
    .grid-container {
        max-width: ${props => props.theme.gridContainerMaxWidth || "75rem"};
    }
    
    @media ${device.tablet} {
        tbody th, thead th, tbody td {
            font-size: 1rem;
            padding: 0.5rem 0.625rem 0.625rem;
        }
    }

    @media print {
        table {
            width: 100%;
            border-collapse: collapse;
            margin: 0.5rem 0;
            border-spacing: 0;
        }

        th, td {
            padding: 0.75rem !important;
            border: 1px solid ${({ theme }) => theme.headerColor || '#0f2d52'};
            vertical-align: top;
        }

        th {
            background-color: ${({ theme }) => theme.headerColor || '#0f2d52'} !important;
            color: ${({ theme }) => theme.headerBackground || 'white'} !important;
            -webkit-print-color-adjust: exact;
            print-color-adjust: exact;
            font-weight: bold;
        }
    }
`;

export const TileContainer = styled.div`
    position: relative;
    width: 100%;
    padding-top: ${props => props.heightWidthRatio || "150%"};
    margin-bottom: ${props => props.marginBottom || "1rem"};
    background-color: ${props => props.backgroundColor || "none"};
    
`;

export const ItemContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-shadow: ${props => props.boxShadow || props.theme.boxShadow || "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"};
    border: ${props => props.tileBorder || props.theme.tileBorder || "solid 1px lightgray"};
    border-radius: ${props => props.theme.tileBorderRadius || "0px"};
    overflow: hidden;
    
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    ${(props) => props.disabled && `
        pointer-events: none;
        cursor: default;
    `}
`;

export const ItemTitle = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: ${props => props.theme.tileTitleHeight || "auto"};
    background: ${props => props.backgroundColor ? `${prepareBgColorRGBA(props.backgroundColor)}` : props.theme.tileTitleBackgroundColor || "#b9b9b9"};
    color: ${props => props.textColor ? `#${props.textColor}` : props.theme.tileTitleColor || props.theme.bodyColor || "#454545"};
    font-weight: ${props => props.theme.tileTitleFontWeight || "normal"};
    line-height: ${props => props.theme.tileTitleLineHeight || "normal"};
    text-transform: uppercase;
    text-align: center;
    padding: ${props => props.theme.tileTitlePadding || "0.1rem"};
    min-height: ${props => props.minHeight || props.theme.tileTitleMinHeight || "inherit"};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.85rem;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.1);
    font-family: 'Heading', sans-serif;
    
    @media ${device.tablet} {
        font-size: 1rem;
        padding: ${props => props.theme.tileTitlePadding || "0.25rem"};
    }
`;

function prepareBgColorRGBA(hex, opacity = 0.8) {
    const regex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
    const parts = hex.match(regex);

    if (!parts) {
        return hex;
    }
    const rgb = [
        parseInt(parts[1], 16),
        parseInt(parts[2], 16),
        parseInt(parts[3], 16),
    ];

    return `rgba(${rgb.join(", ")}, ${opacity})`;
}

const commonLabelStyles = {
    border: 'none',
    margin: 0,
    fontSize: '1rem',
    backgroundColor: props => props.theme.bookNowBackgroundColor || "#a0c800",
    padding: '5px',
    color: props => props.theme.bookNowTextColor || props.theme.buttonColor || "#ffffff",
    borderRadius: '3px'
}

export const BookNowLabel = styled.div`
    ${commonLabelStyles};
    position: absolute;
    top: 3px;
    right: 3px;
`

export const PreviewLabel = styled.div`
    ${commonLabelStyles};
    position: absolute;
    top: 3px;
    left: 3px;
    z-index: 100;
`

export const ContentPreviewLabel = styled.div`
    ${commonLabelStyles};
    display: inline-block;
    margin-bottom: 1rem;
`

export const StyledFooter = styled.div`
    width: 100%;
    background: ${props => props.theme.footerBackground || "rgba(12, 12, 12, 0.75)" };
    color: ${props => props.theme.footerColor || "#ffffff" };
    border-top: ${props => props.theme.footerBorderTop || "none" };
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 500;
    text-align: center;
`;

const commonLinkStyles = {
    fontWeight: props => props.theme.buttonFontWeight || "bold",
    padding: props => props.theme.buttonPadding || "1rem",
    margin: "0.5rem 0 0.5rem",
    display: "inline-block",
    background: props => props.disabled ? "#aaa" : props.theme.buttonBackground || "red",
    color: props => props.theme.buttonColor || "white",
    border: props => props.theme.buttonBorder || "none",
    borderRadius: props => props.theme.buttonRadius || "5px",
    boxShadow: props => props.theme.buttonBoxShadow || "none",
    textTransform: "uppercase",
    lineHeight: 1,
    cursor: "pointer",
    textDecoration: "none",
}

export const DefaultButton = styled.button`
    ${commonLinkStyles};
`;

export const DefaultButtonLink = styled(Link)`
    ${commonLinkStyles};
    :hover, :focus {
        color: ${ props => props.theme.buttonColor || "#333333" };
        text-decoration: none;
    }
`;

export const DefaultButtonLinkExternal = styled.a`
    ${commonLinkStyles};
    :hover, :focus {
        color: ${ props => props.theme.buttonColor || "#333333" };
        text-decoration: none;
    }
`;

export const PhotomatchButtonLink = styled.button`
    color: #069;
    text-decoration: underline;
    cursor: pointer;
`;

export const VodButton = styled(DefaultButton)`
    display: inline-block;
    padding: 0.75rem 2rem;
    text-transform: none;
`;

export const DefaultLink = styled(Link)`
    text-decoration: underline;
    color: ${props => props.theme.defaultLinkColor || "#333333"};
    cursor: pointer;
`;

const commonSecondaryLinkStyles = {
    padding: props =>  props.theme.buttonPadding || "1rem",
    display: "inline-block",
    background: props => props.disabled ? "#aaa" : props.theme.secondaryButtonBackground || props.theme.buttonBackground || "red",
    color: props => props.theme.secondaryButtonColor || props.theme.buttonColor || "white",
    border: props => props.theme.secondaryButtonBorder || props.theme.buttonBorder || "none",
    borderRadius: props => props.theme.buttonRadius || "4px",
    boxShadow: props => props.theme.buttonBoxShadow || "none",
    lineHeight: 1,
    cursor: "pointer",
    textTransform: "uppercase",
    textDecoration: "none",
}

export const SecondaryButton = styled.button`
    ${commonSecondaryLinkStyles};
`;

export const SecondaryButtonLink = styled(Link)`
    ${commonSecondaryLinkStyles};
    :hover, :focus {
        color: ${ props => props.theme.secondaryButtonColor || props.theme.buttonColor || "#333333" };
        text-decoration: none;
    }
`;

export const DefaultSelect = styled.select`
    background: url(/images/select_background.png) no-repeat right #dfdfdf;
    border-radius: 10px;
    border: 1px solid gray;
    max-width: 250px;
    cursor: pointer;
    color: black;
    margin: 0.5rem 0 1rem;
        
    &:focus {
        background: url(/images/select_background.png) no-repeat right #dfdfdf;
    }
    
    option {
        background: #dfdfdf;
    }
`;

export const BasketItemContainer = styled.div`
    background-color: white;
    border: 1px solid #219ED9;
    position: relative;
    padding: 0.5rem;
    font-size: 1rem;
    margin-bottom: 0.625rem;
`;

export const BasketXButton = styled.div`
    position: absolute;
    top: 0;
    right: 0.5rem;
`;

export const CheckboxWrapper = styled.div`
    /* checkboxes */

    input {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }

    label {
      position: relative;
      cursor: pointer;
      padding: 0;
    }


    label.disabled {
      cursor: not-allowed;
      color: ${props => props.theme.checkboxDisabledColor || "#8E98A2"};
    }

    input:checked ~ .checkbox-indicator {
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=);
      background-color: ${props => props.theme.checkboxCheckedBackgroundColor || "#70cbf4"};
      border-color: transparent;
    }

    input:disabled ~ .checkbox-indicator {
      background-color: ${props => props.theme.checkboxDisabledBackgroundColor || "#dae1e7"};
      border-color: ${props => props.theme.checkboxDisabledBorderColor || "#dae1e7"};
    }

    .checkbox-indicator {
      display: inline-block;
      text-align: center;
      background-size: 60% 60%;
      background-position: center center;
      background-repeat: no-repeat;
      pointer-events: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border-width: 1px;
      border-color: black;
      border-style: solid;
      background-color: #fff;
      width: 1.5rem;
      height: 1.5rem;
      margin-right: .5rem;
    }
`;

export const RadioWrapper = styled.div`
    input {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }
    
    label {
      position: relative;
      cursor: pointer;
      padding: 0;
    }
    
    input:checked ~ .radio-indicator:after {
      position: absolute;
      content: '';
      top: 8px;
      left: 8px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
    }
    
    input:checked ~ .radio-indicator {
      background-color: ${props => props.theme.checkboxCheckedBackgroundColor || "#70cbf4"};
    }
    
    input:disabled ~ .radio-indicator {
      background-color: ${props => props.theme.checkboxDisabledBackgroundColor || "#dae1e7"};
      border-color: ${props => props.theme.checkboxDisabledBorderColor || "#dae1e7"};
    }
    
    label.disabled {
      cursor: not-allowed;
      color: ${props => props.theme.checkboxDisabledColor || "#8E98A2"};
    }
    
    .radio-indicator {
      display: inline-block;
      text-align: center;
      border-width: 1px;
      border-color: black;
      border-style: solid;
      background-color: #fff;
      width: 1.5rem;
      height: 1.5rem;
      margin-right: .5rem;
      border-radius: 9999px;
    }   
`;

// TAC-Items and Booking styles

export const Title = styled.h1`
`

export const BookNowButton = styled.div`
    ${commonLinkStyles};
    margin: 0;
    font-size: large;
    padding: 5px;
    position: absolute;
    top: 3px;
    right: 3px;
    border-radius: 3px;
    z-index: 1;
    opacity: 0.8;
`

export const PhotomatchCartEntry = styled.div`
    background-color: white;
    padding: 0.5rem;
    border: 1px solid #70cbf4;
    display: flex;
    align-items: center;
`;

export const ModalContainer = styled.div `
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.8);
    padding: 1em;
    z-index: 600;
    overflow-y: auto;
    
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ModalContent = styled.div`
    width: max-content;
    max-width: 100%;
    max-height: 100%;
    
    @media screen and (min-width: 1900px) {
        display: flex;
    }
`;
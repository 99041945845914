import React, {Component} from 'react';
import {PropTypes} from "prop-types";
import If from "./If";
import styled from "styled-components";
import {connect} from "react-redux";
import { getTranslation } from '../translations/translationUtils';
import DetailImage from "./DetailImage";

const WeatherInfoContainer = styled.div`
    padding: 1em;
    flex-grow: 1;
    align-self: center;
`;

const WeatherBoxContainer = styled.div`
    background: ${props => props.theme.subheaderBackground || "rgb(112, 203, 244);"};
    color: ${props => props.theme.subheaderColor || "#000000"};
`;

class WeatherInformation extends Component {
    render() {
        const weather = this.props.weather;
        const {synopsis, temperature, windSpeed, windDirection, humidity, schedule:{openWeatherCityName}, icon=""} = weather;
        let isWeatherDataAvailable = openWeatherCityName !== null && synopsis != null;

        const scheduleData = {
            date: weather.schedule?.date,
            arrives: getTranslation(weather.schedule?.arrives),
            allOnBoard: getTranslation(weather.schedule?.allOnBoard),
            departs: getTranslation(weather.schedule?.departs),
            currency: getTranslation(weather.schedule?.currency),
            language: getTranslation(weather.schedule?.language),
            nameOfPier: getTranslation(weather.schedule?.nameOfPier),
            localAgentName: getTranslation(weather.schedule?.localAgentName),
            gangway: getTranslation(weather.schedule?.gangway),
        };

        return (

            <div className="grid-x grid-padding-x">
                <div className="cell small-12 medium-4 large-4">
                    <DetailImage image={this.props.configuration.hubUrl + "blobs/image?reference=" + weather.schedule.id} />
                </div>
                <div className="cell small-12 medium-8 large-8 text-left">
                    <h1 className='weather-heading'>
                        {weather.schedule.title.defaultValue}
                    </h1>
                    <If test={isWeatherDataAvailable}>
                        <WeatherBoxContainer className="weather-container">
                            <div className="weather-icon-container">
                                <img
                                    className="weather-icon"
                                    src={`/images/weather/${icon.substring(0,2)}.svg`}
                                    alt={synopsis}
                                />
                            </div>
                            <WeatherInfoContainer>
                                {/* (synopsis != null) same as (synopsis !== null && synopsis !== undefined) */}
                                <If test={synopsis != null}> 
                                    <div>
                                        <b>{this.context.t("synopsis")}:</b> {synopsis}<br/>
                                    </div>
                                </If>
                                <If test={temperature != null}>
                                    <div>
                                        <b>{this.context.t("temperature")}:</b> {temperature}°C<br/>
                                    </div>
                                </If>
                                <If test={windSpeed != null && windDirection != null}>
                                    <div>
                                        <b>{this.context.t("wind")}:</b> {windDirection} {windSpeed}km/h<br/>
                                    </div>
                                </If>
                                <If test={humidity != null}>
                                    <div>
                                        <b>{this.context.t("humidity")}:</b> {humidity}%<br/>
                                    </div>
                                </If>
                            </WeatherInfoContainer>
                        </WeatherBoxContainer>
                    </If>
                    <If test={!isWeatherDataAvailable}>
                            <p><strong>Weather data is not available, Please check back later</strong></p>
                    </If>
                    <div>
                        {
                            (
                                scheduleData.date || scheduleData.arrives ||
                                scheduleData.allOnBoard || scheduleData.departs
                            ) &&
                            <div className='weather-schedule-heading'>
                                {this.context.t("all_aboard_times")}
                            </div>
                        }
                        { scheduleData.date &&
                            <div className="weather-schedule-field">
                                <b>{this.context.t("date")}:</b> {scheduleData.date}
                            </div>
                        }
                        { scheduleData.arrives &&
                            <div className="weather-schedule-field">
                                <b>{this.context.t("arrives")}:</b> {scheduleData.arrives}
                            </div>
                        }

                        { scheduleData.allOnBoard &&
                            <div className="weather-schedule-field">
                                <b>{this.context.t("all_on_board")}:</b> {scheduleData.allOnBoard}
                            </div>
                        }

                        { scheduleData.departs &&
                            <div className="weather-schedule-field">
                                <b>{this.context.t("departs")}:</b> {scheduleData.departs}
                            </div>
                        }

                        {
                            (   scheduleData.currency ||
                                scheduleData.language ||
                                scheduleData.nameOfPier ||
                                scheduleData.localAgentName ||
                                scheduleData.gangway) &&
                            <div className='weather-schedule-heading'>
                                {this.context.t("port_information")}
                            </div>
                        }
                        { scheduleData.currency &&
                            <div className="weather-schedule-field">
                                <b>{this.context.t("currency")}:</b> {scheduleData.currency}
                            </div>
                        }

                        { scheduleData.language &&
                            <div className="weather-schedule-field">
                                <b>{this.context.t("language")}:</b> {scheduleData.language}
                            </div>
                        }

                        { scheduleData.nameOfPier &&
                            <div className="weather-schedule-field">
                                <b>{this.context.t("name_of_pier")}:</b> {scheduleData.nameOfPier}
                            </div>
                        }

                        { scheduleData.localAgentName &&
                            <div className="weather-schedule-field">
                                <b>{this.context.t("ships_local_agent")}:</b> {scheduleData.localAgentName}
                            </div>
                        }

                        { scheduleData.gangway &&
                            <div className="weather-schedule-field">
                                <b>{this.context.t("gangway")}:</b> {scheduleData.gangway}
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        configuration: state.configuration,
    };
};

WeatherInformation.contextTypes = {
    t: PropTypes.func.isRequired
};

export default connect(mapStateToProps, null)(WeatherInformation);

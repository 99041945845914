import React, {Component} from 'react';
import {Route, Router, Switch} from 'react-router-dom';
import browserHistory from './utils/history';
import DashboardComponent from "./modules/dashboard/dashboard";

import ContentOverview from "./modules/content/Overview";
import ContentDetail from "./modules/content/Detail";
import Login from "./modules/login/Login";
import VodOverviewByType from "./modules/vod/OverviewByType";
import GenreOverview from "./modules/vod/GenreOverview";

import VodOverviewByTypeKids from "./modules/vod/OverviewKidsByType";
import {connect} from "react-redux";
import VodDetail from "./modules/vod/VodDetail";

import OverviewMovies from "./modules/vod/movies/OverviewMovies";
import OverviewKidsMovies from "./modules/vod/movies/OverviewKidsMovies";
import OverviewWatchlist from "./modules/vod/watchlist/OverviewWatchlist";
import OverviewSeries from "./modules/vod/series/OverviewSeries";
import VodSerie from "./modules/vod/series/VodSerie";
import VodSeason from "./modules/vod/series/VodSeason";
import TheoPlayerTest from "./components/TheoPlayerTest";
import MusementCategories from "./modules/musement/Categories";
import RiverActivitiesList from "./modules/musement/RiverActivitiesList";
import MusementActivity from "./modules/musement/Activity";
import Basket from "./modules/checkout/Basket";
import {loginToMaxicaster} from "./actions/vodActions";
import Tracker from "./modules/map/Tracker";
import Itinerary from "./modules/holidayplanner/Itinerary";
import BookingConfirmation from './modules/checkout/BookingConfirmation';
import {updateBookings, updateCart} from './actions/cartActions';
import {recordVisit} from './actions/metricsActions';

import RatingOverview from "./modules/ratings/Overview";
import RatingAllInONe from "./modules/ratings/FeedbackAllInOne";
import RatingFeedback from "./modules/ratings/Feedback";

import GamesOverview from "./modules/games/Overview";

import OverviewAudio from "./modules/vod/audio/OverviewAudio";
import AudioSerie from "./modules/vod/audio/AudioSerie";
import AudioSeason from "./modules/vod/audio/AudioSeason";

import OverviewChannels from "./modules/vod/channels/OverviewChannels";
import NotFound from "./components/NotFound";
import AuthRoute from './modules/routing/AuthRoute';
import GuestRoute from "./modules/routing/GuestRoute";
import RetinaLogin from "./modules/login/RetinaLogin";
import RetinaLoginSuccess from "./modules/login/RetinaLoginSuccess";
import Account from "./modules/my/Account";
import Overview from "./modules/itinerary/Overview";
import Detail from "./modules/itinerary/Detail";
import Timetable from "./modules/content/Timetable";
import WeatherOverview from "./modules/weather/WeatherOverview";
import ConditionalAuthRoute from "./modules/routing/ConditionalAuthRoute";
import WhereAndWhen from "./modules/content/WhereAndWhen";
import Video from "./modules/mustering/Video";
import Status from "./modules/mustering/Status";
import Schedule from "./modules/mustering/Schedule";
import VodPrefetch from "./modules/vod/VodPrefetch";
import PlayerSelection from "./modules/vod/PlayerSelection";
import DailySchedule from "./modules/content/DailySchedule";
import ProblemOverview from "./modules/problem/ProblemOverview";
import ProblemForm from "./modules/problem/ProblemForm";
import ProblemTracking from "./modules/problem/ProblemTracking";
import {recordPageview} from "./utils/gaUtils";
import OnboardExcursions from "./modules/excursions/OnboardExcursions";
import OnboardExcursionList from "./modules/excursions/OnboardExcursionList";
import OnboardExcursionDetail from "./modules/excursions/OnboardExcursionDetail";
import {setFooterTypeForPath} from "./actions/navigationActions";
import OnboardExcursionBook from "./modules/excursions/OnboardExcursionBook";
import OnboardBookings from "./modules/holidayplanner/OnboardBookings";
import RestaurantAvailabilityOverview from "./modules/restaurantavailability/RestaurantAvailabilityOverview";
import ActivityOverview from "./modules/activity/ActivityOverview.js";
import ActivityCategory from "./modules/activity/ActivityCategory.js";
import ActivityDetail from "./modules/activity/ActivityDetail.js";
import DiningOverview from "./modules/dining/DiningOverview.js";
import DiningDetail from "./modules/dining/DiningDetail.js";
import SpaOverview from "./modules/spa/SpaOverview.js";
import SpaCategory from "./modules/spa/SpaCategory.js";
import SpaDetail from "./modules/spa/SpaDetail.js";
import TacBooking from "./modules/booking/TacBooking.js";
import ShoreExcursions from "./modules/excursions/ShoreExcursions";
import ShoreExcursionList from "./modules/excursions/ShoreExcursionList";
import ShoreExcursionDetail from "./modules/excursions/ShoreExcursionDetail";
import PhotoMatchOverview from "./modules/photomatch/Overview";
import MyPhotos from "./modules/photomatch/MyPhotos";
import PhotoMatchCart from "./modules/photomatch/Cart";
import PhotoMatchPackages from "./modules/photomatch/Packages";
import PhotoMatchOrders from "./modules/photomatch/Orders";
import PhotoMatchOrderConfirmation from "./modules/photomatch/OrderConfirmation";
import PhotoMatchOrderDetail from "./modules/photomatch/OrderDetail";
import PhotoMatchConsent from "./modules/photomatch/Consent";
import Pin from "./modules/my/Pin";
import QRCodeArticle from "./components/QRCodeArticle";
import CabinMessages from "./modules/cabinmessages/CabinMessages";
import SparkOverview from "./modules/itinerary/SparkOverview";
import SparkDetail from "./modules/itinerary/SparkDetail";
import TvChannelsOverview from "./modules/channels/TvChannelsOverview";
import TvChannel from "./modules/channels/TvChannel";
import Genres from "./modules/seanetvod/Genres";
import Movies from "./modules/seanetvod/Movies";
import MovieDetail from "./modules/seanetvod/MovieDetail";
import ShipDetail from './modules/content/ShipDetail.js';
import GoBySparkOverview from './modules/itinerary/GoBySparkOverview.js';
import ScheduledProgram from './modules/content/ScheduledProgram.js';
import MoviePlayer from "./modules/seanetvod/MoviePlayer";

// import ResortsList from "./modules/excursions/ResortsList";
// import ExcursionsList from "./modules/excursions/ExcursionsList";
// import ExcursionDetail from "./modules/excursions/ExcursionDetail";
// import ExcursionDetailResort from "./modules/excursions/ExcursionDetailResort";
// import Holiday from './modules/holidayplanner/Holiday';
// import MyHoliday from './modules/holidayplanner/MyHoliday';
// import CruiseItinerary from './modules/holidayplanner/CruiseItinerary';
// import Bookings from './modules/holidayplanner/Bookings';

class Routes extends Component {

    componentDidMount() {
        // FIX for old /#/menus and such
        // Any url starting with #/whatever will be replaced with /QR/whatever
        if(window.location.hash && window.location.hash.startsWith("#/")) {
            browserHistory.replace( `/QR/${window.location.hash.substring(2)}`);
        }
        // set footer type for the mounted path
        setFooterTypeForPath(browserHistory.location.pathname);

        //record visit on initial page load (skip if location starts with /content/
        recordVisit(browserHistory.location.pathname);
        if(this.shouldRecordPageview(window.location)) {
            recordPageview(window.location.pathname + window.location.search);
        }

        //register a listener for location changes and do everything that needs to happen on location change
        this.stopRecordingVisits = browserHistory.listen((location) => {
            // record visit in the hub in any case
            recordVisit(location.pathname);

            // record page view in GA only if the url matches the defined list
            if(this.shouldRecordPageview(location)) {
                recordPageview(location.pathname + location.search);
            }

            // handle footer type based on location
            setFooterTypeForPath(location.pathname);

            if (location.pathname.startsWith("/vod/") && !this.isVodAuthenticated(location.pathname)) {
                this.props.loginToMaxicaster()
                    .then((res) => {
                        if (res) {
                            browserHistory.replace(location);
                        } else {
                            browserHistory.replace("/");
                        }
                    })
                    .catch((err) => {
                        browserHistory.replace("/");
                    })

            }
            window.scrollTo(0, 0);
        });

        if (this.props.authentication.isAuthenticated === true && this.props.authentication.sessionType === "Passenger") {
            this.props.updateCart();
            this.props.updateBookings();
        }
    }

    isVodAuthenticated(location) {
        if (!this.props.vod.deviceUuid || !this.props.vod || !this.props.vod.maxicastertoken) {
            return false;
        }
        return true;
    }

    shouldRecordPageview(location) {
        //all dynamic content - articles and categories
        if(location.pathname.startsWith("/content/")) {
            return false;
        }

        //all musement activities detail pages
        if(location.pathname.startsWith("/musement/activity/")) {
            return false;
        }

        //all musement cities lists and activities lists
        if(/^\/musement\/\d/.test(location.pathname)) {
            return false;
        }
        //the musement countries list
        if(/^\/musement$/.test(location.pathname)) {
            return false;
        }

        return true;
    }

    componentWillUnmount() {
        //unregister the listener for location changes
        this.stopRecordingVisits();
    }


    render() {
        return (
            <Router history={browserHistory}>
                <Switch>
                    <ConditionalAuthRoute exact path="/" component={DashboardComponent}/>
                    <ConditionalAuthRoute exact path="/vod/theo/:id" component={TheoPlayerTest}/>

                    <GuestRoute exact path="/login" component={Login}/>
                    <AuthRoute exact path="/holiday" component={Itinerary}/>
                    <AuthRoute exact path="/my_holiday" component={Itinerary}/>

                    <AuthRoute exact path="/my_account" component={Account}/>
                    <AuthRoute exact path="/my_account/pin" component={Pin}/>
                    <AuthRoute exact path="/my_onboard_bookings" component={OnboardBookings}/>
                    <AuthRoute exact path="/tacbooking" component={TacBooking}/>

                    {/* Temporarily removed routes, not used by the current POC will most likely make a comeback */}
                    {/*<AuthRoute exact path="/my_itinerary" component={Itinerary}/>*/}
                    {/*<AuthRoute exact path="/my_bookings" component={Bookings}/>*/}
                    {/*<AuthRoute exact path="/my_resort_excursions" component={ExcursionsList}/>*/}
                    {/*<AuthRoute exact path="/cruise_excursions" component={CruiseItinerary}/>*/}
                    {/*<AuthRoute exact path="/excursions/:itineraryId" component={ExcursionsList}/>*/}
                    {/*<AuthRoute exact path="/excursion/:itineraryId/:excursionId/:showForm?" component={ExcursionDetail}/>*/}
                    {/*<ConditionalAuthRoute exact path="/resorts" component={ResortsList}/>*/}
                    {/*<ConditionalAuthRoute exact path="/resorts/:resortId" component={ExcursionsList}/>*/}
                    {/*<ConditionalAuthRoute exact path="/resorts/:resortId/:excursionId/:showForm?" component={ExcursionDetailResort}/>*/}

                    <ConditionalAuthRoute exact path="/onboard/excursions" component={OnboardExcursions} />
                    <ConditionalAuthRoute exact path="/onboard/excursions/:date" component={OnboardExcursionList} />
                    <ConditionalAuthRoute exact path="/onboard/excursionsbyday/:day" component={OnboardExcursionList} />
                    <ConditionalAuthRoute exact path="/onboard/excursion/:id" component={OnboardExcursionDetail} />
                    <AuthRoute exact path="/onboard/bookexcursion/:id" component={OnboardExcursionBook} />
                    <ConditionalAuthRoute exact path="/shoreexcursions" component={ShoreExcursions} />
                    <ConditionalAuthRoute exact path="/shoreexcursions/:date" component={ShoreExcursionList} />
                    <ConditionalAuthRoute exact path="/shoreexcursion/:id" component={ShoreExcursionDetail} />

                    <ConditionalAuthRoute exact path="/musement/activity/:activityId" component={MusementActivity}/>
                    <ConditionalAuthRoute exact path="/musement/:countryId?/:cityId?" component={MusementCategories}/>
                    <ConditionalAuthRoute exact path="/musement-river/excursions" component={RiverActivitiesList}/>


                    <AuthRoute exact path="/basket" component={Basket} />
                    <AuthRoute exact path="/confirmation" component={BookingConfirmation} />

                    <ConditionalAuthRoute exact path="/content/overview" component={ContentOverview}/>
                    <ConditionalAuthRoute exact path="/content/overview/:id" component={ContentOverview}/>
                    <ConditionalAuthRoute exact path="/content/detail/:id" component={ContentDetail}/>

                    <ConditionalAuthRoute exact path="/activity/overview" component={ActivityOverview}/>
                    <ConditionalAuthRoute exact path="/activity/category/:id" component={ActivityCategory}/>
                    <ConditionalAuthRoute exact path="/activity/detail/:id" component={ActivityDetail}/>

                    <ConditionalAuthRoute exact path="/dining/detail/:id" component={DiningDetail}/>
                    <ConditionalAuthRoute exact path="/dining/overview" component={DiningOverview}/>

                    <ConditionalAuthRoute exact path="/spa/overview" component={SpaOverview}/>
                    <ConditionalAuthRoute exact path="/spa/category/:id" component={SpaCategory}/>
                    <ConditionalAuthRoute exact path="/spa/detail/:id" component={SpaDetail}/>

                    <ConditionalAuthRoute exact path="/vod/login" component={Login}/>

                    <ConditionalAuthRoute exact path="/map/tracker/:id" component={Tracker}/>

                    <ConditionalAuthRoute exact path="/vod/overview" component={VodOverviewByType}/>
                    <ConditionalAuthRoute exact path="/vod/overview/genre/:type" component={GenreOverview}/>

                    <ConditionalAuthRoute exact path="/vod/detail/:id" component={VodDetail}/>
                    <ConditionalAuthRoute exact path="/vod/overview/movies" component={OverviewMovies}/>
                    <ConditionalAuthRoute exact path="/vod/overview/movies/:genreid" component={OverviewMovies}/>
                    <ConditionalAuthRoute exact path="/vod/overview/watchlist" component={OverviewWatchlist}/>

                    <ConditionalAuthRoute exact path="/vod/overview/series" component={OverviewSeries}/>
                    <ConditionalAuthRoute exact path="/vod/overview/series/:genreid" component={OverviewSeries}/>
                    <ConditionalAuthRoute exact path="/vod/overview/serie/:id" component={VodSerie}/>
                    <ConditionalAuthRoute exact path="/vod/overview/serie/:serieid/:season" component={VodSeason}/>

                    <ConditionalAuthRoute exact path="/vod/overview/audios" component={OverviewAudio}/>
                    <ConditionalAuthRoute exact path="/vod/overview/audios/:genreid" component={OverviewAudio}/>
                    <ConditionalAuthRoute exact path="/vod/overview/audio/:id" component={AudioSerie}/>
                    <ConditionalAuthRoute exact path="/vod/overview/audio/:serieid/:season" component={AudioSeason}/>

                    <ConditionalAuthRoute exact path="/vod/overview/kids" component={VodOverviewByTypeKids}/>
                    <ConditionalAuthRoute exact path="/vod/overview/kids/movies" component={OverviewKidsMovies}/>

                    <ConditionalAuthRoute exact path="/vod/overview/channels" component={OverviewChannels}/>


                    <ConditionalAuthRoute exact path="/vod/:id" component={VodPrefetch}/>
                    <ConditionalAuthRoute exact path="/vod/channel/:id" component={PlayerSelection}/>
                    <ConditionalAuthRoute exact path="/seanetvod" component={Genres}/>
                    <ConditionalAuthRoute exact path="/seanetvod/genre/:id" component={Movies}/>
                    <ConditionalAuthRoute exact path="/seanetvod/movie/:id" component={MovieDetail}/>
                    <ConditionalAuthRoute exact path="/seanetvod/moviePlayer" component={MoviePlayer}/>


                    <ConditionalAuthRoute exact path="/channels" component={TvChannelsOverview}/>
                    <ConditionalAuthRoute exact path="/channel/:id" component={TvChannel}/>

                    <ConditionalAuthRoute exact path="/ratings/overview" component={RatingOverview}/>
                    <ConditionalAuthRoute exact path="/ratings/allinone" component={RatingAllInONe}/>
                    <ConditionalAuthRoute exact path="/ratings/feedback/:id" component={RatingFeedback}/>

                    <ConditionalAuthRoute exact path="/games/overview" component={GamesOverview}/>

                    <ConditionalAuthRoute exact path="/retina/login/:id/:room" component={RetinaLogin}/>
                    <Route exact path="/retina/login/:token" component={RetinaLogin}/>
                    <ConditionalAuthRoute exact path="/retina/loginsuccess" component={RetinaLoginSuccess}/>

                    <ConditionalAuthRoute path="/itinerary/overview" component={Overview}/>
                    <ConditionalAuthRoute path="/itinerary/detail/:id" component={Detail}/>
                    <ConditionalAuthRoute path="/itinerary/spark/:date" component={SparkDetail}/>
                    <ConditionalAuthRoute path="/itinerary/spark" component={SparkOverview}/>
                    <ConditionalAuthRoute path="/itinerary/go" component={GoBySparkOverview}/>

                    <ConditionalAuthRoute path="/timetable" component={Timetable}/>

                    <ConditionalAuthRoute path="/dailyprogram/:date?" component={DailySchedule}/>
                    <ConditionalAuthRoute path="/scheduledprogram" component={ScheduledProgram}/>

                    <ConditionalAuthRoute path="/weather" component={WeatherOverview}/>

                    <ConditionalAuthRoute path="/wherewhen" component={WhereAndWhen}/>

                    <ConditionalAuthRoute path="/shipdetail" component={ShipDetail}/>

                    <AuthRoute path="/muster/video" component={Video}/>
                    <AuthRoute path="/muster/status" component={Status}/>
                    <AuthRoute path="/muster/schedule" component={Schedule}/>

                    <AuthRoute path="/photomatch/overview" component={PhotoMatchOverview}/>
                    <AuthRoute path="/photomatch/consent" component={PhotoMatchConsent}/>
                    <AuthRoute path="/photomatch/myphotos" component={MyPhotos}/>
                    <AuthRoute path="/photomatch/cart" component={PhotoMatchCart}/>
                    <AuthRoute path="/photomatch/packages" component={PhotoMatchPackages}/>
                    <AuthRoute path="/photomatch/orderconfirmation" component={PhotoMatchOrderConfirmation}/>
                    <AuthRoute path="/photomatch/orders" component={PhotoMatchOrders}/>
                    <AuthRoute path="/photomatch/orderDetail" component={PhotoMatchOrderDetail}/>


                    <AuthRoute path="/cabinmessages" component={CabinMessages}/>
                    <ConditionalAuthRoute path="/problem/overview" component={ProblemOverview}/>
                    <AuthRoute path="/problem/new" component={ProblemForm}/>
                    <AuthRoute path="/problem/tracking" component={ProblemTracking}/>
                    <ConditionalAuthRoute path="/QR/:hash" component={QRCodeArticle}/>
                    <ConditionalAuthRoute path="/restaurantavailability/overview/:id" component={RestaurantAvailabilityOverview}/>
                    <ConditionalAuthRoute component={NotFound} />
                </Switch>
            </Router>
        );
    }
}

const mapStateToProps = state => {
    return {
        vod: state.vod,
        authentication: state.authentication,
    };
};

export default connect(mapStateToProps, {loginToMaxicaster, updateCart, updateBookings}) (Routes);

import React from 'react';
import {formatDuration} from "../../utils/dateTimeUtils";
import { ReactComponent as Clock } from '../../resources/images/clock.svg';
import styled from 'styled-components';

const ClockIcon = styled(Clock)`
    fill: ${props => props.theme.buttonBackground || '#6B207D'};
    stroke: ${props => props.theme.buttonBackground || '#6B207D'};
`;

class ExcursionDuration extends React.Component {
    render() {

        if(!this.props.duration && !this.props.publishedDuration){
            return null;
        }

        return (
            <div className="">
                <ClockIcon className="w-4 h-4 align-middle mr-2" />
                <span className="align-middle text-grey-darker">{ this.props.publishedDuration ? this.props.publishedDuration : formatDuration(this.props.duration)}</span>
            </div>
        )
    }
}

export default ExcursionDuration;
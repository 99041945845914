import React from "react";

import ImageButton from "./ImageButton";

export default class OverviewImageButton extends React.Component {

    render() {
        return (
            <div className="cell">

                <ImageButton item={this.props.item}
                             link={this.props.link}
                             redirectUrl={this.props.redirectUrl}
                             linkState={this.props.linkState}
                             title={this.props.title}
                             subtitle={this.props.subtitle}
                             thumbnail={this.props.thumbnail}
                             heightWidthRatio={this.props.heightWidthRatio}
                             showTitle={this.props.item?.showTitle}
                             showBookNow={this.props.item?.showBookNow}
                             textColor={this.props.item?.textColour}
                             backgroundColor={this.props.item?.backgroundColour}
                             bookable={this.props.bookable}
                />
            </div>)
    }
}
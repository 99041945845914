import React, {Component} from 'react';
import {connect} from "react-redux";
import {fetchFromHub} from "../../actions/networkActions";
import {imagePath} from "../../utils/imageUtils";

class Status extends Component {
    state = {
        passengers: [],
        musterStationVisitRequired: false,
        loadingError: false,
    };

    componentDidMount() {
        this.props.fetchFromHub('muster/passenger/status', this.updatePassengers, this.handleError);
    }

    updatePassengers = ({passengers, musterStationVisitRequired}) => {
        this.setState({
            passengers,
            musterStationVisitRequired,
        });
    };

    handleError = () => {
        this.setState({
            loadingError: true,
        });
    }

    render() {
        if(this.state.loadingError) {
            return (
                <div>
                    The muster status can currently not be loaded. Please try again later.
                </div>
            );
        }

        if(this.state.passengers.length === 0) {
            return (
                <div>
                    Loading...
                </div>
            )
        }

        const me = this.state.passengers.filter(passenger => passenger.isLoggedInUser)[0];
        const passengers = this.state.passengers.filter(passenger => !passenger.isLoggedInUser);
        const overAllComplete = this.state.musterStationVisitRequired ?
            this.state.passengers.every(passenger => passenger.isVideoWatched && passenger.isMusterScanPerformed) :
            this.state.passengers.every(passenger => passenger.isVideoWatched);
        const nrPassengers = this.state.passengers.length;

        const infoMessage = overAllComplete ?
            "" :
            nrPassengers > 1 ?
                "Please assist your group in completing virtual mustering as soon as possible. Speak to a member of crew if you are experiencing difficulties." :
                "Please complete virtual mustering as soon as possible. Speak to a member of crew if you are experiencing difficulties."

        const title = this.props.location?.state?.title

        return (
            <div>
                <h1>{ title ? title : "Muster Status" }</h1>

                {/* your status */}
                <div className="font-bold">Your Status</div>
                <PassengerStatus
                    watchedVideo={me.isVideoWatched}
                    mustered={me.isMusterScanPerformed}
                    musterStationVisitRequired={this.state.musterStationVisitRequired}
                />

                {/* group status */}
                {nrPassengers > 1 &&
                <div>
                    <div className="mt-4 font-bold">Your Group's Status</div>
                    <PartStatus complete={overAllComplete} label="Overall Status" />
                </div>
                }

                {infoMessage &&
                <div>
                    {infoMessage}
                </div>
                }

                {/* other members individual status */}
                {passengers.map((passenger, index) =>
                    <div className="mt-4" key={index}>
                        <PassengerStatus
                            name={passenger.name}
                            watchedVideo={passenger.isVideoWatched}
                            mustered={passenger.isMusterScanPerformed}
                            musterStationVisitRequired={this.state.musterStationVisitRequired}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default connect(null, {fetchFromHub})(Status);

function PassengerStatus(props) {
    return (
        <div className={`pt-2 ${props.name ? "border-top-blue" : ""}`}>
            {props.name &&
            <div>
                {props.name}:
            </div>
            }

            <PartStatus complete={props.watchedVideo} label="Muster Video" />
            {props.musterStationVisitRequired &&
                <>
                    <PartStatus complete={props.mustered} label="Muster Station Visit" />
                    <PartStatus complete={props.watchedVideo && props.mustered} label="Overall Status" />
                </>
            }
        </div>
    )
}

function PartStatus(props) {
    const iconPath = props.complete ? "check-circle.svg" : "exclamation-circle.svg";
    const image = imagePath(iconPath);

    return (
        <div className="flex items-center">
            <div className="mr-2 flex">
                <img src={image} alt=""/>
            </div>
            <div>
                {props.label}: {props.complete ? "Complete" : "Incomplete"}
            </div>
        </div>
    )
}
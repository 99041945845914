import React from 'react';
import styled from "styled-components";
import {device} from "../../resources/styles";
import {PropTypes} from "prop-types";

const WeatherContainer = styled.div`
  background-color: rgba(0,0,0,0.6);
  position: absolute;
  bottom: 5px;
  right: 5px;
  z-index: 400;
  width: 95%;
  padding: 0.5rem;
  color: white;
  span {
    font-size: 0.85rem;
  }

  @media ${device.largePhone} {
    span {
      font-size: 0.9rem;
    }
    width: 85%;
  }
  
  @media ${device.tablet} {
    span {
      font-size: 1rem; 
    }
    width: 50%;
  }
  
  @media ${device.laptop} {
    padding: 1rem;
    width: 35%;
  }
`

const WeatherInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`

const Temperature = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`

const TempValue = styled.p`
  font-size: 2rem;
  line-height: 1;
  margin-bottom: 0;
`

const Border = styled.p`
  border-bottom: 1px solid white;
  margin-bottom: 0.5rem;
`

const Stats = styled.div`
  line-height: 1.2;
  max-width: 45%;
  @media ${device.tablet} {
    line-height: 1.5;
  }
`;

const WeatherIcon = styled.img`
  width: 40px;
  height: 40px;
  @media ${device.tablet} {
    width: 50px;
    height: 50px;
  }
`;

const WeatherHeading = styled.h4`
  font-weight: bold;
  color: white;
`

const TrackerWeather = ({weatherData}, context) => {

    if (!weatherData) return null;

    const { synopsis, icon= "", temp, humidity, feels_like, windDirection, windSpeed } = weatherData;

    return (
        <WeatherContainer>
            <WeatherHeading>{context.t('trackerweatherheading')}</WeatherHeading>
            <Border/>
            <WeatherInfoContainer>
                { icon &&
                    <WeatherIcon src={`/images/weather/${icon.substring(0,2)}.svg`}
                         alt={synopsis}
                    />
                }
                { temp &&
                    <div>
                        <Temperature>
                            <TempValue>{Math.trunc(temp)}</TempValue>
                            <span>°C</span>
                        </Temperature>
                        { feels_like &&
                            <span style={{marginBottom: 0}}>{context.t('feelslike')} <b>{Math.trunc(feels_like)}</b>°C</span>
                        }
                    </div>
                }
                <Stats>
                    { synopsis &&
                        <div>
                            <span style={{textTransform: "capitalize"}}>{synopsis}</span>
                        </div>
                    }
                    { humidity &&
                        <div>
                            <span>{context.t('humidity')}: </span>
                            <span>{humidity}%</span>
                        </div>
                    }
                    { (windSpeed && windDirection) &&
                        <div>
                            <span>{context.t('wind')}: </span>
                            <span>{windDirection} </span>
                            <span>{Math.trunc(windSpeed)}km/h</span>
                        </div>
                    }
                </Stats>
            </WeatherInfoContainer>
        </WeatherContainer>
    );
};

TrackerWeather.contextTypes = {
    t: PropTypes.func.isRequired
}

export default TrackerWeather;
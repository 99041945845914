import React from "react";
import OverviewImageButtonPanel from "../../components/OverviewImageButtonPanel";
import OverviewImageButton from "../../components/OverviewImageButton";
import connect from "react-redux/es/connect/connect";

class Overview extends React.Component {
    constructor() {
        super();
        this.state = {
            games: [
                {id: 'TeenieMeanies', title: 'Teenie Meanies'},
                {id: 'SolitaireClassic', title: 'Solitaire'},
                {id: 'BirdVsBird', title: 'Bird vs Bird'},
                {id: '2048', title: '2048'},
                {id: 'Eye2Eye', title: 'Eye 2 Eye'},
                {id: 'TwoToMatch', title: 'Two To Match'},
                {id: 'HoneyImHome', title: "Honey I'm home"},
                {id: 'SudokuClassic', title: "Sudoku"},
                {id: 'PicFix', title: "Pic Fix"}
            ]
        };
    }

    render() {
        return (
            <OverviewImageButtonPanel>
                {
                    this.state.games.map((game) => {
                        const link = this.props.gamesUrl + "/games/" + game.id + "/index.html";
                        const thumbnail = "/games/" + game.id + "/logo.png";

                        return (
                            <OverviewImageButton
                                key={game.id}
                                redirectUrl={link}
                                title={game.title}
                                thumbnail={thumbnail}
                            />
                        )
                    })
                }
            </OverviewImageButtonPanel>
        )
    }
}

const mapStateToProps = state => {
    return {
        gamesUrl: state.configuration.gamesUrl
    };
};

export default connect(mapStateToProps)(Overview);
import React, {useEffect, useState} from 'react';
import {getFromHubNew} from "../../actions/networkActions";
import Loading from "../../components/Loading";
import OverviewImageButtonPanel from "../../components/OverviewImageButtonPanel";
import {getTranslation} from "../../translations/translationUtils";
import DateButton from "../../components/DateButton";

function OnboardExcursions(props) {
    const [itinerary, setItinerary] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const itineraryData = await getFromHubNew("itinerary/schedules");
            setItinerary(itineraryData);
            setLoading(false);
        }

        fetchData();
    }, []);

    if(loading) {
        return (
            <Loading/>
        )
    }

    return (
        <div>
            <OverviewImageButtonPanel>
                {
                    itinerary.map((day, index) =>
                        <div key={day.id} className="cell">
                            <DateButton link={day.date ? "/onboard/excursions" : "/onboard/excursionsbyday"}
                                        linkId={day.date ? day.date.replace(/\//g, "-") : day.day}
                                        scheduleId={day.id}
                                        date={day.date}
                                        day={day.day}
                                        disabled={day.atSea}
                                        index={index}
                                        title={getTranslation(day.title) || day.title.defaultValue}
                                        thumbnail={day.image}
                                        linkState={{
                                            portName: day.title.defaultPlainValue,
                                            arrivalTime: day.arrives.defaultPlainValue,
                                            departureTime: day.departs.defaultPlainValue,
                                            portDate: day.date,
                                        }}
                            />
                        </div>
                    )}
            </OverviewImageButtonPanel>
        </div>
    );
}

export default OnboardExcursions;
import React from 'react';
import Price from "./Price";
import PropTypes from 'prop-types';

function ExcursionPrices(props, context) {
    return (
        <>
            {props.adultPrice ? <Price price={props.adultPrice} postText={ " " + context.t("per_adult")} /> : null}
            {props.childPrice !== "N/A" ? (
                <Price hidePoundSign={props.hidePoundSign} price={props.childPrice} postText={` ${context.t("per_child")} ${props.ageRange}`}/>
            ) : null}
        </>
    );
}

ExcursionPrices.contextTypes = {
    t: PropTypes.func.isRequired,
}

export default ExcursionPrices;
import React from 'react';
import styled, {useTheme} from "styled-components";
import {device} from "../resources/styles";
import {useSelector} from "react-redux";
import ImageWithFallback from "./ImageWithFallback";

const TitleArea = styled.div `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 100%;
    background-color: ${props => props.backgroundColor};
    height: 100%;
    font-size: 0.7rem;
    padding: 0.2rem;
    border-radius: ${props => props.theme.tileBorderRadius || "0px"};
    color: ${props => props.color || props.theme.bodyColor || "#0a0a0a"};
    div {
        padding: 0.1rem;
    }

    div:nth-child(2n-1) {
        font-weight: bold;
        text-transform: uppercase;
    }

    @media ${device.tablet} {
        font-size: 1rem;
        padding: 0.5rem;
        div {
            padding: 0.2rem;
        }        
    }
`;

const DateTileContainer = ({index, scheduleId, children}) => {
    const theme = useTheme();
    const hubUrl = useSelector(state => state.configuration?.hubUrl);
    const indexConstrainedBy8 = index % 8;
    let backgroundColor;
    const colorFromTheme = theme.itineraryGradientColor || "#dfffff";
    const opacity = `0.${indexConstrainedBy8 + 2}`;

    if (colorFromTheme.includes('#')) {
        const colorConvert = () => {
            const hex = colorFromTheme.substring(1).match(/.{1,2}/g);
            return 'rgba(' + [
                    parseInt(hex[0], 16),
                    parseInt(hex[1], 16),
                    parseInt(hex[2], 16)]
                + ',' + opacity + ')'
                ;
        }
        backgroundColor = colorConvert();
    } else {
        const newColor = colorFromTheme.replace(/rgb/i, "rgba");
        backgroundColor = newColor.replace(/\)/i,',' + opacity + ')');
    }

    if (theme.useDateButtonImageBackground) {
        return (
            <>
                <ImageWithFallback src={ hubUrl + "blobs/image?reference=" + scheduleId }
                                   alt="Date button"
                />
                <TitleArea backgroundColor="rgba(0,0,0,0.6)"
                           color="white"
                >
                    {children}
                </TitleArea>
            </>
        )
    }
    return (
            <TitleArea backgroundColor={backgroundColor}>
                {children}
            </TitleArea>
    );
};

export default DateTileContainer;
import React from 'react';
import styled from 'styled-components';
import { DefaultButton, device, ModalContainer } from '../resources/styles';
import { formatSparkFromToTime } from '../utils/dateTimeUtils';
import { ReactComponent as LocationPin } from '../resources/images/location-pin.svg';
import { ReactComponent as Clock } from '../resources/images/clock.svg';
import {ReactComponent as ChargeableIcon} from '../resources/images/chargeable-icon.svg';
import { store } from '../App';
import { ReactComponent as Pound } from '../resources/images/pound-icon.svg';
import { getTranslation } from '../translations/translationUtils';
import ImageWithFallback from './ImageWithFallback';

const Content = styled.div`
  width: max-content;
  max-width: 100%;
  max-height: 100%;
  position: relative;

  @media (min-width: 600px) {
    width: 75%;
  }

  @media (min-width: 1024px) {
    width: 50%;
  }

  .fill-icon {
        fill: ${props => props.theme.buttonBackground || '#6B207D'};
        stroke: ${props => props.theme.buttonBackground || '#6B207D'};
    }
    
    .title{
        font-size: 1.5rem;
        color: ${props => props.theme.headerColor || "#5a5a5a"};
        font-weight: bold;
        margin-bottom: 1rem;
    }
    
    p, .description {
        font-size: 1.25rem;
    }

    .pound-container{
      width: 5rem;
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom-right-radius: 1rem;
      background-color: ${props => props.theme.buttonBackground || '#6B207D'};
    }

    .pound{
      width: 2rem;
      height: 2rem;
      fill: #fff;
      stroke: #fff;
    }
    
    .image{
        width: 100%;
        object-fit: cover;
        aspect-ratio: 2.5;
    }

    @media ${device.tablet} {
        .pound{
            width: 2.5rem;
            height: 2.5rem;
        }
    }
`;

const EventModal = ({event, setSelectedEventIndex}) => {
    let { name, venue, images, imageId, start, end, description, attributes=[], eventOverview } = event;
    const hubUrl = store.getState().configuration.hubUrl;

    let image="";
    if(imageId){
        image = hubUrl + `blobs/image?reference=${imageId}`; // Event from hub (scheduled programme)
    }else{
        let imageObject = images.find(obj => obj.types.includes("Full")) || images.find(obj => obj.types.includes("Preview")); // Event from Spark
        image = imageObject?.url?.replace("://go.", "://app.") || event.image?.replace("://go.", "://app.") || "";
    }

    let isChargeableEvent = attributes.includes("Chargeable Experience");

    if(!description){
        description = <div dangerouslySetInnerHTML={{__html: getTranslation(eventOverview)}}/>; // Event from hub (scheduled programme)
    }

    return(
        <ModalContainer onClick={() => setSelectedEventIndex(null)}>
            <Content onClick={(e) => {e.stopPropagation()}}>
                <div className="bg-white">
                    {isChargeableEvent && (
                        <div className='absolute top-0 left-0 pound-container'>
                            <ChargeableIcon className="pound"/>
                        </div>
                    )}
                    <ImageWithFallback src={image || `/images/spark_event_image_large.jpg`} fallback="/images/spark_event_image_large.jpg" alt={name} className="image" />
                    <div className='p-4'>
                        <h3 className='title'>{name}</h3>
                        <div className='flex items-center mb-2'>
                            <Clock className="fill-icon w-6 h-6 align-middle mr-2 inline" />
                            {(start || end) ? (
                                <p className="m-0">{start} - {end}</p>
                            ) : (
                                <p className='m-0'>{formatSparkFromToTime(event)}</p>
                            )}
                        </div>
                        {venue && (
                            <div className='flex items-center mb-2'>
                                <LocationPin className="fill-icon w-6 h-6 align-middle mr-2 inline" />
                                <p className='m-0'>{venue}</p>
                            </div>
                        )}
                        {isChargeableEvent && (
                            <div className='flex items-center mb-2 pr-4'>
                                <Pound className="fill-icon w-6 h-6 align-middle mr-2 inline"/>
                                <p className="m-0">Chargeable Event</p>
                            </div>
                        )}
                        {description && (
                            <div className="mx-2 mt-4 description">
                                {description}
                            </div>
                        )}
                        <div className="mt-4 text-center">
                            <DefaultButton onClick={() => setSelectedEventIndex(null)} className='w-1/2'>Ok</DefaultButton>
                        </div>
                    </div>
                </div>
            </Content>
        </ModalContainer>
    )
};

export default EventModal

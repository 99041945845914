import React from 'react';
import {connect}  from 'react-redux';
import {SecondaryButton} from "../resources/styles";
import {PropTypes} from "prop-types";
import PdfViewer from "./PdfViewer";
import {truncateStringLongerThan, removePdfExtension} from "../utils/stringUtils";

class PdfItems extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedPdf: "",
        }
    }

    download = (pdfUrl) => {
        const url = this.props.configuration.hubUrl + pdfUrl.substring(1);
        window.open(url);
    };

    toggleSelectedPdf = (selected) => {
        this.setState({
            selectedPdf: this.state.selectedPdf === selected ? "" : selected,
        });
    }

    render() {
        if(this.props.configuration.hubSettings?.deviceType === "Kiosk" && !this.props.configuration?.hubSettings?.uiSettings?.showPdfsOnKiosk) {
            return null;
        }
        
        return (
            <div>
                {this.props.items.map((pdfItem) => (
                    <div key={pdfItem.id}>
                        {this.props.configuration.hubSettings?.deviceType === "App" ?

                            <SecondaryButton  className="my-2" onClick={() => this.download(pdfItem.url)}>
                                {removePdfExtension(truncateStringLongerThan(pdfItem.description ? pdfItem.description : pdfItem.name , 25))}
                            </SecondaryButton>:

                            <SecondaryButton  className="my-2" onClick={() => this.toggleSelectedPdf(pdfItem.url)}>
                                {`${this.state.selectedPdf === pdfItem.url ? "Hide" : ""} ${ removePdfExtension(truncateStringLongerThan(pdfItem.description ? pdfItem.description : pdfItem.name, 25 ))}`}
                            </SecondaryButton>

                        }
                    </div>
                ))}
                
                {this.state.selectedPdf &&
                    <PdfViewer filename={this.props.configuration.hubUrl + this.state.selectedPdf.substring(1)}/>
                }
            </div>
        );
    }
}

PdfItems.contextTypes = {
    t: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return { configuration: state.configuration };
};

export default connect(mapStateToProps)(PdfItems);

import React, {Fragment} from 'react';
import {connect} from "react-redux";
import {fetchFromHub} from "../../actions/networkActions";
import {PropTypes} from "prop-types";
import If from "../../components/If";
import {getTranslation} from "../../translations/translationUtils";
import {formatYMDtoDMY, get12HourFormattedTime, getDayFromDate} from "../../utils/dateTimeUtils";
import DailySchedule from "./DailySchedule";
import {withTheme} from "styled-components";


class Timetable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            initialize: true,
            events: [],
            showDeckHeader: false,
            showCategoryHeader: false,
            showLocationHeader: false,
            showEndTimeHeader: false
        };
    }

    componentDidMount() {
        if (this.props.contentId) {
            this.props.fetchFromHub(`cms/articles/findEventsByArticleId/` + this.props.contentId, this.updateEvents);
        }
    }

    isEmpty = (object) => { for(var i in object) { return false; } return true; };

    isPropertyEmpty = (property) => {
        return property == null || property.trim() === '' || property.trim() === 'Late';
    }

    updateEvents = (events) => {
        let filteredEvents = [];
        let showDeckHeader = false;
        let showCategoryHeader = false;
        let showLocationHeader = false;
        let showEndTimeHeader = false;
        for(let i = 0; i < events.length; i++) {
            if (!this.isEmpty(events[i].fromDate)) {
                filteredEvents.push(events[i]);
            }
            if(events[i].locationDetail?.defaultValue !== ""){
                showDeckHeader = true;
            }
            if(events[i].category?.defaultValue !== ""){
                showCategoryHeader = true;
            }
            if(events[i].location?.defaultValue !== ""){
                showLocationHeader = true;
            }
            if(!this.isPropertyEmpty(events[i].endTime)){
                showEndTimeHeader = true;
            }
        }

        this.setState({
            events: filteredEvents,
            initialize: false,
            showDeckHeader,
            showCategoryHeader,
            showLocationHeader,
            showEndTimeHeader
        });
    };

    render() {

        if (this.props.scheduleLayout) {
            return <DailySchedule
                isTimetable={true}
                timetableEvents={this.state.events}
                title={this.props.title}
            />
        }

        return (
            <div>
                <div className="grid-x grid-padding-x">
                    {/* Title Only for Print Media */}
                    <h3 className="hidden print-block text-center cell medium-12 mt-2">{this.props.title}</h3>

                    <If test={!this.state.initialize && this.state.events.length === 0}>
                        <div className="cell medium-12 text-center">
                            <h4>{this.context.t("no_events")}</h4>
                        </div>
                    </If>

                    <If test={!this.state.initialize && this.state.events.length > 0}>
                    <div className="cell medium-12 text-center">
                        <table className="animated fadeIn">
                            {this.props.useExcursionLabels ?
                                <ExcursionsTimetableHeader events={this.state.events} showLocationHeader={this.state.showLocationHeader} showEndTimeHeader={this.state.showEndTimeHeader} /> :
                                this.props.usePortLabels ?
                                    <PortTimetableHeader /> :
                                    <TimetableHeader events={this.state.events} showDeckHeader={this.state.showDeckHeader} showCategoryHeader={this.state.showCategoryHeader} showLocationHeader={this.state.showLocationHeader} showEndTimeHeader={this.state.showEndTimeHeader} />
                            }
                            <tbody>
                            {
                                this.state.events.map((event) => {
                                    let startTime = event.startTime ? event.startTime : "";
                                    if (event.startTimeDescription.defaultPlainValue !== "") {
                                        startTime = event.startTimeDescription.defaultPlainValue;
                                    } else if (event.startTime && this.props.theme.use12HourTimeFormat) {
                                        startTime =  get12HourFormattedTime(event.startTime)
                                    }

                                    let endTime = event.endTime ? event.endTime : this.context.t('late');
                                    if (event.endTimeDescription.defaultPlainValue !== "") {
                                        endTime = event.endTimeDescription.defaultPlainValue;
                                    } else if (event.endTime && this.props.theme.use12HourTimeFormat) {
                                        endTime = get12HourFormattedTime(event.endTime)
                                    }
                                    return (
                                        <Fragment key={event.id}>
                                            {this.props.usePortLabels ?
                                                <PortTimetableBody event={event}/>
                                                :
                                                this.props.useExcursionLabels ?
                                                    <ExcursionTimetableBody event={event} showLocationHeader={this.state.showLocationHeader} showEndTimeHeader={this.state.showEndTimeHeader} />
                                                    :
                                                    <tr id={event.id} key={event.id}>
                                                        <td>{startTime}</td>
                                                        <If test={this.state.showEndTimeHeader === true}>
                                                            <td>{endTime}</td>
                                                        </If>
                                                        <td>{getTranslation(event.title)}</td>
                                                        <If test={this.state.showDeckHeader === true}>
                                                            <td>{getTranslation(event.locationDetail)}</td>
                                                        </If>
                                                        <If test={this.state.showCategoryHeader === true}>
                                                            <td>{getTranslation(event.category)}</td>
                                                        </If>
                                                        <If test={this.state.showLocationHeader === true}>
                                                            <td>{getTranslation(event.location)}</td>
                                                        </If>
                                                    </tr>
                                            }
                                        </Fragment>
                                    )
                                    })
                            }
                            </tbody>
                        </table>
                    </div>
                    </If>
                </div>
            </div>
        );
    }
}


function TimetableHeader({events, showCategoryHeader, showDeckHeader, showLocationHeader, showEndTimeHeader}, context) {
    return (
        <thead>
            <tr className="text-center">
                <th>{context.t("timetable_start")}</th>
                <If test={showEndTimeHeader === true}>
                    <th>{context.t("timetable_end")}</th>
                </If>
                <th>{context.t("timetable_title")}</th>
                <If test={showDeckHeader === true}>
                    <th>{context.t("timetable_deck")}</th>
                </If>
                <If test={showCategoryHeader === true}>
                    <th>{context.t("timetable_category")}</th>
                </If>
                <If test={showLocationHeader === true}>
                    <th>{context.t("timetable_location")}</th>
                </If>
            </tr>
        </thead>
    );
}

function ExcursionsTimetableHeader({events, showLocationHeader, showEndTimeHeader},context) {

    return (
        <thead>
            <tr className="text-center">
                <th>{context.t("meeting_time")}</th>
                <If test={showEndTimeHeader === true}>
                    <th>{context.t("departure_time")}</th>
                </If>
                <th>{context.t("experience")}</th>
                <th>{context.t("bus_or_group")}</th>
                <If test={showLocationHeader === true}>
                    <th>{context.t("meeting_location")}</th>
                </If>
            </tr>
        </thead>
    )
}

function PortTimetableHeader(props, context) {
    return (
        <thead>
        <tr className="text-center">
            <th>{context.t("date")}</th>
            <th>{context.t("day")}</th>
            <th>{context.t("port")}</th>
            <th>{context.t("arrival")}</th>
            <th>{context.t("departure")}</th>
        </tr>
        </thead>
    )
}

function PortTimetableBody({event}, context) {
    const removeLateText = (string) => {
        if (string && string.trim() === 'Late') {
            return ""
        } else return string
    }

    return (
        <tr id={event.id} key={event.id}>
            <td>{formatYMDtoDMY(event.fromDate)}</td>
            <td>{context.t(getDayFromDate(event.fromDate))}</td>
            <td>{event.title?.defaultPlainValue || event.location?.defaultPlainValue }</td>
            <td>{event.startTime}</td>
            <td>{removeLateText(event.endTime)}</td>
        </tr>
    )
}

function ExcursionTimetableBody({event, showLocationHeader, showEndTimeHeader }) {
    return(
        <tr id={event.id} key={event.id}>
            <td>{event.startTime}</td>
            <If test={showEndTimeHeader === true}>
                <td>{event.endTime}</td>
            </If>
            <td>{getTranslation(event.title)}</td>
            <td>{getTranslation(event.locationDetail)}</td>
            <If test={showLocationHeader === true}>
                <td>{getTranslation(event.location)}</td>
            </If>
        </tr>
    )
}

[Timetable, TimetableHeader,ExcursionsTimetableHeader, PortTimetableHeader, PortTimetableBody].forEach(component => {
    component.contextTypes = {
        t: PropTypes.func.isRequired
    };
});

const mapStateToProps = state => {
    return { configuration: state.configuration };
};

export default connect(mapStateToProps, {fetchFromHub: fetchFromHub})(withTheme(Timetable));
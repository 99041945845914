import React from "react";
import browserHistory from "../utils/history";
import moment from "moment";
import {ItemContainer, TileContainer, device} from "../resources/styles";
import styled, {useTheme} from "styled-components";
import {PropTypes} from "prop-types";
import DateTileContainer from "./DateTileContainer";
import {useSelector} from "react-redux";

const DayOfWeek = styled.div `
    display: none;
    @media ${device.tablet} {
        display: inline;
    }
`;

const Container = styled.div `
    background-color: white;
    height: 100%;
    text-align: center;
    border-radius: ${props => props.theme.tileBorderRadius || "0px"};
    cursor: pointer;
`;

const DateButton = ({link, linkId, scheduleId, date, disabled, index, title, subtitle, day, linkState}, context) => {

    const theme = useTheme();
    const lang = useSelector(state => state.i18nState.lang);

    //This assumes lang from Hub maps exactly to usable moment locale
    moment.locale(lang)
    const momentDate = moment(date, "DD/MM/YYYY");

    return (
        <TileContainer heightWidthRatio={theme.tileHeightWidthRatio || "100%"}>
            <ItemContainer onClick={() => browserHistory.push(`${link}/${linkId}`, linkState)}
                           disabled={disabled}
            >
                <Container>
                    <DateTileContainer index={index}
                        scheduleId={scheduleId}
                    >
                        { date ? (
                            <>
                                <div>{momentDate.format("DD MMM")}</div>
                                <DayOfWeek>{momentDate.format("dddd")}</DayOfWeek>
                            </>
                        ):(
                            <div>{context.t('day')} {day}</div>
                        ) }
                        <div>{title}</div>
                        <div>{subtitle}</div>
                    </DateTileContainer>
                </Container>
            </ItemContainer>
        </TileContainer>
    )
};

DateButton.contextTypes = {
    t: PropTypes.func.isRequired
}

export default DateButton;

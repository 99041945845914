import React, {Component} from 'react';
import {connect} from "react-redux";
import {fetchFromHub} from "../../actions/networkActions";

class Schedule extends Component {

    constructor(props) {
        super(props);
        this.state = {
            events: [],
            images: [],
        }
    }

    componentDidMount() {
        this.props.fetchFromHub('cms/articles/findByHashtag?hashtag=musterSchedule', this.updateArticle);

    }

    updateArticle = (response) => {
        if(response.length === 0) {
            return;
        }

        const articleId = response[0].id;
        this.props.fetchFromHub(`cms/articles/findEventsByArticleId/${articleId}`, this.updateEvents);
        this.props.fetchFromHub(`blobs/images/${articleId}`, this.updateImages);
    }

    updateImages = images => {
        this.setState({
            images,
        });
    }

    updateEvents = events => {
        this.setState({
            events,
        });
    }


    render() {
        const style = {
            wordWrap: "break-word"
        };

        const title = this.props.location?.state?.title

        return (
            <div>
                <h4>{title ? title : "Muster Schedule"}</h4>
                <table>
                    <thead>
                    <tr>
                        <th>Start</th>
                        <th>End</th>
                        <th>Title</th>
                        <th>Deck</th>
                        <th>Location</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.events.map((event) => {
                            const startTime = event.startTime;
                            const endTime = event.endTime;

                            return (<tr id={event.id} key={event.id}>
                                <td style={style}>{startTime}</td>
                                <td style={style}>{endTime}</td>
                                <td style={style}>{event.title.defaultValue}</td>
                                <td style={style}>{event.locationDetail.defaultValue}</td>
                                <td style={style}>{event.location.defaultValue}</td>
                            </tr>)
                        })
                    }
                    </tbody>
                </table>
                {this.state.images.map(image => <div className="mt-4" key={image.id}>
                    <img alt="muster map" src={this.props.hubUrl + "blobs/image?id=" + image.id + "&width=800"} />
                </div>)}

            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        hubUrl: state.configuration.hubUrl
    };
};
export default connect(mapStateToProps, {fetchFromHub})(Schedule);
import React from 'react';
import {connect} from "react-redux";
import {PropTypes} from "prop-types";
import {fetchFromHub} from "../../actions/networkActions";
import If from "../../components/If";
import moment from "moment";
import {getTranslation} from "../../translations/translationUtils";
import styled, {withTheme} from "styled-components";
import {device} from "../../resources/styles";
import {formatYmdToFullCalendarDate, get12HourFormattedTime} from "../../utils/dateTimeUtils";

const Title = styled.h3`
    font-size: 2.5rem;
`;

const Description = styled.div`
    font-size: 1.5rem;
    text-align: ${props => props.isScheduleProgram ? "center" : "left" };
`;

const EventContainer = styled.div`
  
    > div {
      padding: 0.6rem 0;
    }
    
    > div:not(:first-child) {
      border-top: ${ props => props.theme.noDailyScheduleBorder ? "none" : "1px solid darkslategray" };
    }

    @media ${device.tablet} {
      font-size: 1.3rem;
    }
  
`;

const Time = styled.div`
    width: 100%;
    display: inline-block;
    vertical-align: top;
    @media ${device.tablet} {
       width: 17%; 
    }
`;

const Event = styled.div`
    display: inline-block;
    @media ${device.tablet} {
      width: 80%;
    }
    p {
      margin-bottom: 0;
    }
`;

const Divider = styled.hr`
    max-width: 100%;
    margin: 5px 0 15px 0;
`;

class DailySchedule extends React.Component {
    constructor() {
        super();
        this.state = {
            id: null,
            initialize: true,
            title: null,
            overviewDescription: null,
            description: null,
            eventDate: null,
            events: [],
            dateString: "",
        };
    }

    componentDidMount() {
        let dateString = "";
        const date = this.props.match?.params?.date?.substring(0, 10).replace(/-/g, "/") || 'today' ;

        // Skip fetching if events data is passed from timetable or if the url is scheduledprogram
        if (!this.props.isTimetable) {
            if (date === "today") {
                dateString = moment();
                this.props.fetchFromHub(`cms/dailySchedule`, this.updateEvents);
            } else {
                if (date === "tomorrow") {
                    dateString = moment().add(1, 'd');
                    this.props.fetchFromHub(`cms/dailyScheduleTomorrow`, this.updateEvents);
                } else {
                    dateString = moment(date, "DD/MM/YYYY");
                    this.props.fetchFromHub(`cms/dailyScheduleByDate?date=${date}`, this.updateEvents);
                }
            }
            this.setState({
                dateString: dateString.format("dddd, D MMMM YYYY")
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.timetableEvents && this.props.timetableEvents !== prevProps.timetableEvents) {
            this.updateEvents(this.props.timetableEvents);
        }
    }

    isEmpty = (object) => { for(var i in object) { return false; } return true; };

    updateEvents = (response) => {
        let dailyprogram = {};
        let events = [];

        // If page is content article, filter response to match daily program uploaded for the articleId
        const id = this.props.contentId;
        if (id) {
            dailyprogram = response.find(schedule => schedule.id === id);
            events = dailyprogram.events;
        } else if (response.length > 0) {
            dailyprogram = response[response.length - 1];
            events = dailyprogram.events;
        }
        // Use timetableEvents if passed as prop, sort using only start time
        if (this.props.isTimetable && this.props.timetableEvents.length) {
            const sortedEvents = this.props.timetableEvents.sort((a, b) => {
                const timeA = new Date(`1970-01-01T${a.startTime}`);
                const timeB = new Date(`1970-01-01T${b.startTime}`);
                return timeA - timeB;
            })
            const date = this.props.timetableEvents[0].fromDate;
            this.setState({
                title: this.props.title,
                overviewDescription: "",
                description: "",
                events: sortedEvents,
                initialize: false,
                dateString: formatYmdToFullCalendarDate(date)
            });
        } else {
            // If page is content article, filter response to match daily program uploaded for the articleId
            const id = this.props.contentId;
            if (id) {
                dailyprogram = response.find(schedule => schedule.id === id);
                events = dailyprogram.events;
            } else if (response.length > 0) {
                dailyprogram = response[response.length - 1];
                events = dailyprogram.events;
            }

            this.setState({
                title: dailyprogram.title,
                overviewDescription: dailyprogram.overviewDescription,
                description: dailyprogram.description,
                events: events,
                initialize: false
            });
        }
    };

    removeHtmlTags(string) {
        if (!string) {
            return null
        }
        return string.replace(/<\/?[^>]+(>|$)/g, "");
    }


    render() {
        return (
                <div className="margin-horizontal-9375">
                    {!this.state.initialize && this.state.events.length === 0 &&
                        <h4>{this.context.t("no_events")}</h4>
                    }
                    <If test={!this.state.initialize && this.state.events.length > 0}>
                        <Title>{getTranslation(this.state.title) || this.state.title}</Title>
                        <Description>
                            <p> { this.removeHtmlTags(getTranslation(this.state.overviewDescription)) } </p>
                            <p> { this.state.dateString } </p>
                        </Description>
                        <EventContainer>
                            {
                                this.state.events.map((event, index) => {
                                    let startTime = "";
                                    if (event.startTimeDescription.defaultValue){
                                        startTime = getTranslation(event.startTimeDescription);
                                    } else if (event.startTime) {
                                        startTime = this.props.theme.use12HourTimeFormat ? get12HourFormattedTime(event.startTime) : event.startTime;
                                    }

                                    let endTime = "";
                                    if(event.endTimeDescription.defaultValue){
                                        endTime = getTranslation(event.endTimeDescription);
                                    } else if (event.endTime) {
                                        endTime = this.props.theme.use12HourTimeFormat ? get12HourFormattedTime(event.endTime) : event.endTime;
                                    }

                                    let showDividerTop = false;
                                    let showDividerBottom = false;
                                    if(event.stylingRequired === true){
                                        showDividerTop = true;
                                        showDividerBottom = true;
                                        if ( index > 0 && this.state.events[index - 1].stylingRequired === true) {
                                            showDividerTop = false;
                                        }
                                    }

                                    return (
                                            <div id={event.id} key={event.id}>
                                                { showDividerTop && <Divider /> }
                                                <Time>
                                                    {startTime}
                                                    <If test={ startTime && endTime }> - </If>
                                                    {endTime}
                                                </Time>
                                                <Event>
                                                    <div>{getTranslation(event.title)}</div>
                                                    { event.location?.defaultValue !== "" && <p>{getTranslation(event.location)} {getTranslation(event.locationDetail)}</p> }
                                                    { event.eventOverview?.defaultValue !== "" && <p>{this.removeHtmlTags(getTranslation(event.eventOverview))}</p> }
                                                </Event>
                                                { showDividerBottom && <Divider /> }
                                            </div>
                                    )
                                    })
                            }
                        </EventContainer>
                    </If>
                </div>
        );
    }
}


DailySchedule.contextTypes = {
    t: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return { configuration: state.configuration };
};

export default connect(mapStateToProps, {fetchFromHub}) (withTheme(DailySchedule));
import React, { useCallback, useState, useMemo } from 'react';
import {useGet} from "../../hooks/useGet";
import {convertToFormattedDate, isDateTodayOrFuture} from "../../utils/dateTimeUtils";
import DateNavigator from '../../components/DateNavigator';
import styled from 'styled-components';
import { getTranslation } from '../../translations/translationUtils';
import { device } from '../../resources/styles';
import FullScreenLoader from '../../components/FullScreenLoader';
import { getFromHubNew } from '../../actions/networkActions';
import GoBySparkDetail from '../itinerary/GoBySparkDetail';

const Header = styled.h1`
    color: ${props => props.theme.headerColor || "#5a5a5a" };
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;
`;

const Title = styled.p`
    color: ${props => props.theme.headerColor || "#5a5a5a" };
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;

    @media ${device.largePhone} {
        font-size: 1.3rem;
    }
`;

function ScheduledProgram() {

    const [selectedDay, setSelectedDay] = useState(null);
    const [events, setEvents] = useState({});
    const [loading, setLoading] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState(null);

    const {isLoading, error, data: dates} = useGet("itinerary/schedules");

    const activeDates = useMemo(()=>{
        return dates?.filter(date => isDateTodayOrFuture(date.date));
    },[dates]);

    const fetchData = useCallback(async (date) => {
        setLoading(true);
        try {
            let response = await getFromHubNew(`cms/dailyScheduleByDate?date=${date}`);
            setEvents(oldEvents => ({...oldEvents, [date]: {
                events: response[0]?.events || [],
                validOn: new Date()
            }}));
        } catch (error) {
            console.error("Failed to fetch data:", error);
        } finally {
            setLoading(false);
        }
    }, []);

    const onSelectionChange = useCallback((index)=>{
        setSelectedDay(activeDates[index]);
        let date = activeDates[index].date;
        let dataExpiryLimit = 2 * 60 * 1000; // 2 minutes 
        
        let shouldUpdate = !events[date] || (events[date] && (new Date()-events[date].validOn > dataExpiryLimit));
        if(shouldUpdate){
            fetchData(date);
        }
    },[fetchData, events, activeDates]);

    if(isLoading) {
        return <FullScreenLoader/>
    }

    if(error) {
        return <div>
            There has been an error: {error}
        </div>
    }
    
    let modifiedEvents = events[selectedDay?.date]?.events.map(event => ({
        id: event.id,
        name: getTranslation(event.title),
        venue: getTranslation(event.location),
        imageId: event.id,
        start : event.startTime,
        end: event.endTime,
        eventOverview: event.eventOverview
    }));

    return (
        <div>
            <Header>
                <img className="w-6 h-6 align-middle mr-2 inline" alt="clock icon" src={require('../../resources/images/daily_calendar.svg')} />
                Scheduled Programme
            </Header>
            {(activeDates && activeDates.length) && (
                <DateNavigator onSelectionChange={onSelectionChange} dates={activeDates} />
            )}
            {(selectedDay && !loading) ? (
                <>
                    <Title> {convertToFormattedDate(selectedDay.date)} | {selectedDay.atSea ? "at sea" : getTranslation(selectedDay.title)} </Title>
                    <GoBySparkDetail
                        events={modifiedEvents} 
                        filters={filters}
                        setFilters={setFilters}
                        showFilters={showFilters}
                        setShowFilters={setShowFilters}
                    />
                </>
            ):(
                <FullScreenLoader />
            )}
        </div>
    );
}

export default ScheduledProgram;
import React from "react";
import moment from "moment";
import logoutIcon from '../resources/images/logouticon.svg';
import languageIcon from '../resources/images/languageIcon.svg';
import {connect} from "react-redux";
import browserHistory from "../utils/history";
import {PropTypes} from "prop-types";
import If from "./If";
import Modal from "./Modal";
import {logoutAtMaxicaster} from "../actions/vodActions";
import {logoutUser} from "../actions/authenticationActions";
import basketIcon from '../resources/images/basket.svg';
import styled, {withTheme} from 'styled-components';
import {getTranslation} from "../translations/translationUtils";
import {device} from "../resources/styles";
import InactivityLogout from "./InactivityLogout";
import CurrencyUtils from "../utils/currencyUtils";
import MissedOffers from "./photomatch/MissedOffers";
import VodLocaleModal from "./VodLocaleModal";

const StyledHeader = styled.div`
    padding: ${ props => 
        ( props.theme.isHeaderTallOnDS && props.isDigitalSignage ) ? "2rem" 
        : props.theme.isHeaderTallOnApp? "1rem"
        : "0.625rem 1rem" 
    };    
    display: flex;
    align-items: center;
    max-height: ${props => props.theme.isHeaderTallOnApp ? "120px" : "53px"};

    @media ${device.tablet} {
        max-height: ${ props => (props.theme.isHeaderTallOnDS && props.isDigitalSignage) || props.theme.isHeaderTallOnApp ? "164px" : "67px"};
          padding: ${ props =>
                  props.theme.isHeaderTallOnApp? "1rem 1.5rem"
                  : "0.625rem 1.5rem"
          };
    }
`;

const MainHeader = styled(StyledHeader)`
    justify-content: space-between;
    color: ${props => props.theme.headerColor || "#444444"};
    background: ${props => props.theme.headerBackgroundGradient || "none"};
    background-color: ${props => props.theme.headerBackground || "#ffffff" };
    background-image: ${props => props.theme.headerBackgroundImage || "none"};
    font-weight: ${props => props.theme.headerFontWeight || "normal"};
    border-bottom: ${props => props.theme.headerBorderBottom || "none"};
    font-size: 1.1rem;
    box-shadow: ${props => props.theme.headerBoxShadow || "none"};
    flex-direction: ${ props=> props.theme.logoAlignRight ? "row-reverse" : "row" };
    
    .header-logo {
        display: flex;
        align-items: center;
        flex: ${ props=> props.theme.logoWideOnMobile ? "3" : 1 };
        justify-content: ${ props => 
                props.theme.centerAppHeaderLogoOnly ? "center" 
                : props.theme.logoAlignRight ? "end" 
                : "start" 
        };

        @media print {
            justify-content: center;
            flex: 1;
        }
    }
    
    .clock {
        text-align: center;
        display: none;
        order: ${props => props.theme.isHeaderTallOnDS && props.isDigitalSignage ? "1" : "0"};
    }
    
    .app-title {
        text-align: ${props => props.theme.logoAlignRight ? "left" : "right"};
        line-height: ${props => props.theme.isHeaderTallOnDS && props.isDigitalSignage ? "2rem" : 1};
        text-transform: ${props => props.theme.headerTextTransform || "uppercase"};
        flex: 2;
        display: ${props => props.theme.centerAppHeaderLogoOnly ? "none" : "inline"};
        font-family: 'Heading', sans-serif;
        
        @media print {
            display: none;
        }
    }
    
    img {
        max-height: ${props => props.theme.isHeaderTallOnApp ? "60px" : "40px"};
        max-width: ${props => props.theme.isHeaderTallOnApp ? "100%" : "85%"};
        cursor: pointer;
    }  
    
    
    @media ${device.tablet} {
        font-size: 2rem;
        
        img {
            max-height: ${props => props.theme.isHeaderTallOnApp ? "80px" : "33px"};
            max-width: 70%;
        }
    }
    
    @media ${device.laptop} {
        .clock {
            display: inline-block;
        }
        
        .header-logo {
            flex: 1;
        }
        
        .app-title {
            font-size: ${props => props.theme.isHeaderTallOnDS && props.isDigitalSignage ? "1.5rem" : "2rem"};
            padding-right: ${props => props.theme.isHeaderTallOnDS && props.isDigitalSignage ? "1rem" : "0"};
            flex: 1;
        }
        
        .clock {
            font-size: ${props => props.theme.isHeaderTallOnDS && props.isDigitalSignage ? "3rem" : "2rem"};
            border-left: ${props => props.theme.isHeaderTallOnDS && props.isDigitalSignage ? "1px solid " + props.theme.headerColor : "none"};
            padding-left: ${props => props.theme.isHeaderTallOnDS && props.isDigitalSignage ? "1rem" : "0"};
        }
        
        img {
            max-height: ${ props => 
                ( props.theme.isHeaderTallOnDS && props.isDigitalSignage ) ? "100px"
                : props.theme.isHeaderTallOnApp ? "80px"
                : "40px"
            };
            max-width: 370px;
        }
        
    }
    
`;

const SubHeader = styled(StyledHeader)`
    justify-content: space-between;
    background: ${props => props.theme.subheaderBackgroundGradient || "none"};
    border-top: ${props => props.theme.subheaderBorderTop || "none"};
    border-bottom: ${props => props.theme.subheaderBorderBottom || "none"};
    background-color: ${props => props.theme.subheaderBackground || "#bbbbbb"};
    color: ${props => props.theme.subheaderColor || "#333333"};
    box-shadow: ${props => props.theme.subheaderBoxShadow || "none"};
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    
    .info-text {
        font-size: 1rem;
        font-weight: bold;
    }
    
    @media ${device.tablet} {
        .info-text {
            font-size: 1.1875rem 
        }
    }
`;

const SubheaderElementContainer = styled.div`
    display: flex;
    justify-content: ${props => props.justifyContent || 'flex-start'};
    @media ${device.laptop} {
        width: ${props => props.deviceType === "Kiosk" ? "30%" : "50%"};
    }
`;

const SubHeaderButton = styled.button`
    cursor: pointer;
    padding: 0.5rem 1rem;
    font-size: 0.93rem;
    display: flex;
    align-items: center;
    background-color: ${props => props.theme.subheaderButtonBackground || props.theme.secondaryButtonBackground || props.theme.buttonBackground || "red"};
    border: ${props => props.theme.subheaderButtonBorder || props.theme.secondaryButtonBorder || "none"};
    border-radius: ${props => props.theme.subheaderButtonBorderRadius || props.theme.buttonRadius || "3px"};
    
    color: ${props => props.theme.subheaderButtonColor || props.theme.secondaryButtonColor || props.theme.buttonColor || "white"};
    
    img {
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
        -webkit-filter: ${props => props.theme.iconColor || "none"};
        display: ${props => props.theme.subheaderButtonIconDisplay || "inline-block"};
    }

    /* Remove button texts on mobile only when photomatch button is present */
    
    span {
        display: ${props => props.displayTextMobile || 'inline'};
    }
    
    @media ${device.tablet} {
        span {
            display: inline;
            font-size: 1.1875rem;
        }
    }
`;

class Header extends React.Component {

    constructor(props) {
        super(props);
        const now = moment();
        this.state = {
            date: now.format("dddd, D MMMM"),
            time: now.format("HH:mm"),
            showVodLocaleModal: false,
        }

    }

    componentDidMount() {
        // setting interval to every 5 seconds as we are only showing hours and minutes - to be changed to more/less frequent if needed
        if(this.props.configuration.hubSettings.enableClock) {
            this.interval = setInterval(this.tick, 5000);
        }
    }

    componentWillUnmount() {
        if(this.props.configuration.hubSettings.enableClock) {
            clearInterval(this.interval);
        }
    }

    tick = () => {
        const now = moment();
        this.setState({
            date: now.format("dddd, D MMMM"),
            time: now.format("HH:mm"),
        });
    }

    home = () => {
        browserHistory.push("/");
    };

    photoCartHasEntries = () => {
        return !!(this.props.cart?.totals?.bulkAdded || this.props.cart?.totals?.grandCount);
    }

    formatAmount = () => {
        if(this.photoCartHasEntries()) {
            return `£${CurrencyUtils.convertToCurrency(this.props.cart.totals.grandTotal)}`
        }
        return null;
    };

    render() {
        const appTitle = this.props.configuration.hubSettings ? getTranslation(this.props.configuration.hubSettings.appName) : null;
        const profileId = this.props.configuration.hubSettings ? this.props.configuration.hubSettings.profileId : "";

        let isAuthenticated = false;
        let customerInformation = "";
        let showLogoutButton = true;
        if (this.props.authentication) {
            isAuthenticated = this.props.authentication.isAuthenticated;
            customerInformation = this.props.authentication.bookingReference;

            if (this.props.authentication.pms && this.props.authentication.pms.name) {
                customerInformation = this.props.authentication.pms.name;
                const sessionName = this.props.authentication.sessionName;
                if (this.props.authentication.sessionType === "RemotePms" && sessionName === "Retina" ) {
                    showLogoutButton = false;
                }
            }
        }

        const showPhotomatchCartButton = this.props.consent && this.photoCartHasEntries();

        // TODO - change this to accommodate for 2 shopping carts - musement one and the existing one
        return (
            <div>
                <Modal show={this.props.isFetching}/>

                <MainHeader isDigitalSignage={this.props.configuration.hubSettings.isDigitalSignage} isHeaderTallOnDS={this.props.theme.isHeaderTallOnDS}>
                    {profileId &&
                        <div className="header-logo">
                            <img onClick={() => this.home()} src={this.props.configuration.hubUrl + "blobs/image?reference=" + profileId} alt="header logo"/>
                        </div>
                    }

                    {this.props.configuration.hubSettings.enableClock &&
                        <div className="clock">{this.state.time}</div>
                    }

                    {appTitle &&
                        <div className="app-title">
                            {appTitle}
                            {this.props.configuration.hubSettings.enableClock && this.props.configuration.hubSettings.isDigitalSignage && this.props.theme.isHeaderTallOnDS &&
                                <div>{this.state.date}</div>
                            }
                        </div>
                    }
                </MainHeader>

                <If test={ (isAuthenticated === true && !this.props.configuration.hubSettings.enableWayfinder) || this.props.showLocalesButton }>
                    <SubHeader>
                        <SubheaderElementContainer
                            justifyContent={'flex-start'}
                            deviceType={this.props.configuration.hubSettings.deviceType}
                        >
                            <div className="info-text"> {customerInformation}</div>
                        </SubheaderElementContainer>
                        {/* TODO - uncomment this after basket functionality should be turned on */}
                        {/*<button className="margin-horizontal-625" onClick={() => {browserHistory.push("/basket")}}>*/}
                        {/*    <img alt="basket icon" src={basketIcon}/>*/}
                        {/*    <span>{this.context.t("basket")} {this.props.nrBasketExcursions}</span>*/}
                        {/*</button>*/}
                        {this.props.configuration.hubSettings.deviceType === "Kiosk" &&
                            <SubheaderElementContainer
                                justifyContent={'center'}
                                deviceType={this.props.configuration.hubSettings.deviceType}
                            >
                                <InactivityLogout/>
                            </SubheaderElementContainer>
                        }
                        <SubheaderElementContainer
                            justifyContent={'flex-end'}
                            deviceType={this.props.configuration.hubSettings.deviceType}
                        >
                            {showPhotomatchCartButton && !this.props.showLocalesButton &&
                            <SubHeaderButton displayTextMobile={'none'} className="margin-horizontal-625" onClick={() => {browserHistory.push("/photomatch/cart")}}>
                                <img alt="basket icon" src={basketIcon}/>
                                <span>{this.context.t("basket")} {this.formatAmount()}</span>
                            </SubHeaderButton>
                            }
                            { this.props.showLocalesButton &&
                                <SubHeaderButton
                                    onClick={() => this.setState({showVodLocaleModal: true})}
                                    className="margin-horizontal-625"
                                >
                                    <img alt="language icon" src={languageIcon}/>
                                    <span>{this.context.t("vod-language")}</span>
                                </SubHeaderButton>
                            }
                            <If test={isAuthenticated && showLogoutButton}>
                                <SubHeaderButton displayTextMobile={showPhotomatchCartButton ? 'none' : 'inline'} onClick={() => this.logoutInClass()}>
                                    <img alt="logout icon" src={logoutIcon}/>
                                    <span>{this.context.t("logout")}</span>
                                </SubHeaderButton>
                            </If>
                        </SubheaderElementContainer>
                    </SubHeader>
                    {showPhotomatchCartButton &&
                    <MissedOffers/>
                    }
                    { this.state.showVodLocaleModal &&
                        <VodLocaleModal
                            closeModalCallback={() => {
                                this.setState({showVodLocaleModal: false});
                            }}
                        />
                    }
                </If>
            </div>
        )
    }

    async logoutInClass() {
        if (this.props.vod.maxicastertoken) {
            await this.props.logoutAtMaxicaster();
        }
        await this.props.logoutUser();
        browserHistory.push("/");
    }
}



Header.contextTypes = {
    t: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        authentication: state.authentication,
        lang: state.i18nState.lang,
        isFetching: state.vod.isFetching,
        vod: state.vod,
        nrBasketExcursions: state.cart.nrExcursions ? `(${state.cart.nrExcursions})` : "",
        configuration: state.configuration,
        cart: state.photomatch.cart,
        consent: state.photomatch.consent,
        showLocalesButton: state.seanetVod.showLocales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logoutUser: () => dispatch(logoutUser()),
        logoutAtMaxicaster: () => dispatch(logoutAtMaxicaster()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps) (withTheme(Header));
import React, {useEffect} from 'react';
import {useSeanetGenres} from "../../hooks/useSeanetGenres";
import OverviewImageButtonPanel from "../../components/OverviewImageButtonPanel";
import OverviewImageButton from "../../components/OverviewImageButton";
import {withTheme} from "styled-components";
import {getSeanetUrl} from "../../actions/networkActions";
import {setShowSeanetLocales} from "../../actions/seanetVodActions";
import {PropTypes} from "prop-types";

function Genres(props, context) {
    const genres = useSeanetGenres();

    useEffect(() => {
        setShowSeanetLocales(true);
        return () => {
            setShowSeanetLocales(false);
        };
    }, []);

    if(genres === null) {
        return <div>
            loading...
        </div>
    }


    return (
        <div>
            <h2 className="margin-horizontal-9375">{context.t('genres')}</h2>
            <OverviewImageButtonPanel>
                {Object.values(genres).map(genre =>
                    <OverviewImageButton key={genre.id}
                                         link={"/seanetvod/genre/" + genre.id}
                                         title={genre.name}
                                         thumbnail={`${getSeanetUrl()}sti/genre-images/thumb/${genre.id === 0 ? "all.jpg" : `${genre.id}.jpg`}`}
                                         heightWidthRatio="100%"
                    />
                )}

            </OverviewImageButtonPanel>
        </div>
    );

}

Genres.contextTypes = {
    t: PropTypes.func.isRequired
}

export default  withTheme(Genres);

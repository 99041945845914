import React from 'react';
import {fetchFromHub} from '../../actions/networkActions';
import {connect} from 'react-redux';
import * as L from 'leaflet';
import {Map, Marker, TileLayer, ZoomControl} from 'react-leaflet';
import {imagePath} from '../../utils/imageUtils';
import TrackerWeather from "./TrackerWeather";
import styled from "styled-components";
import Checkbox from "../../components/Checkbox";
import {PropTypes} from "prop-types";
import {device} from "../../resources/styles";

const TrackerContainer = styled.div`
  position: relative;
`;

const TrackerSettingsContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-start;
  span {
    color: ${props => props.theme.bodyColor || "#0a0a0a"};
    line-height: 1.2;
  }
  input {
    display: inline;
  }
  div {
    margin-right: 0.5rem;
  }
  @media ${device.tablet} {
    span {
      font-size: 1rem;
    }
    justify-content: flex-end;
    div {
      margin: 0 0 0 1rem;
    }
  }
`

class Tracker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            gpsProfile: null,
            zoom: 4,
            autoCenterMap: true,
            customMapPosition: false,
            hideWeather: false,
        };
        this.eventSource = null;
    }

    //--

    componentDidMount() {
        this._updateContent();
        this._registerGpsProfileEvents();
    };

    //--

    componentWillUnmount() {
        if (this.eventSource) {
            this.eventSource.close();
        }
    }

    //--

    _registerGpsProfileEvents = () => {
        this.eventSource = new EventSource(this.props.configuration.hubApiUrl + 'cms/events/register');
        this.eventSource.onmessage = message => {
            const gpsProfile = JSON.parse(message.data).gpsProfile;
            this.setState({gpsProfile});
        }
    };

    //--

    _updateContent = () => {
        this.props.fetchFromHub('gps/current', gpsProfile => {
            this.setState({gpsProfile});
        });
    };

    //--

    _isHeadingUnknown = () => {
        const heading = this.state.gpsProfile.advancedInformation.heading;
        return heading === undefined || heading === 511;
    }

    _isCourseUnknown = () => {
        const course = this.state.gpsProfile.advancedInformation.course;
        return course === undefined || course === 511;
    }

    _calculateIconName = () => {
        if (this.state.gpsProfile.dataType !== 'Ship' &&
            this.state.gpsProfile.dataType !== 'Aircraft') {
            return 'circle.svg';
        }

        if(this._isCourseUnknown() && this._isHeadingUnknown()) {
            return "circle.svg";
        }

        return this.state.gpsProfile.dataType.toLowerCase() + '.png';
    };

    _calculateRotation = () => {
        if(!this._isHeadingUnknown()) {
            return `${this.state.gpsProfile.advancedInformation.heading - 90}deg`;
        }

        if(!this._isCourseUnknown()) {
            return `${this.state.gpsProfile.advancedInformation.course - 90}deg`;
        }

        return "0deg";
    };

    //--

    _updateZoom = event => {
        this.setState({zoom: event.target.getZoom()});
    };

    _setCustomPosition = (event) => {
        const mapCenter = event.target.getCenter();
        const customMapPosition = [mapCenter.lat, mapCenter.lng];

        if(!this.state.autoCenterMap) {
            this.setState({
                customMapPosition,
            });
        }
    };

    _toggleAutoCenter = () => {
        if(this.state.autoCenterMap) {
            this.setState({
                autoCenterMap: false,
                customMapPosition: [this.state.gpsProfile.latitude, this.state.gpsProfile.longitude],
            });
        }
        else {
            this.setState({
                autoCenterMap: true,
                customMapPosition: false,
            });
        }
    };

    render() {
        if (this.state.gpsProfile === null) {
            return false;
        }

        // Icon size will be 80px until level 14, then increase by 40px on each level.
        // If we want to keep it up with the scale of the map it should be doubled with each new zoom level.
        const iconSize = this.state.zoom < 14 ? 80 : 80 + (this.state.zoom - 13) * 40;
        const iconPosition = [this.state.gpsProfile.latitude, this.state.gpsProfile.longitude];
        const mapPosition = this.state.autoCenterMap ? iconPosition : this.state.customMapPosition;

        const icon = L.divIcon({
            iconSize: [iconSize, iconSize],
            className: 'someTestClassName',
            html: `<img 
                    alt="map marker"
                    style="transform: rotate(${this._calculateRotation()});"
                    height=${iconSize} 
                    width=${iconSize} 
                    src=${imagePath('map/' + this._calculateIconName())}>`
        });

        const weather = this.state.gpsProfile?.advancedInformation?.weather;

        return (
            <div>
                <h1>{this.context.t('tracker')}</h1>
                <div>
                    <TrackerSettingsContainer>
                        <Checkbox label={this.context.t("disableautocentering")}
                                  checked={!this.state.autoCenterMap}
                                  handleCheckboxChange={this._toggleAutoCenter}
                        />
                        { weather &&
                            <Checkbox label={this.context.t("hideweather")}
                                      checked={this.state.hideWeather}
                                      handleCheckboxChange={ () => this.setState(prevState => ({
                                          hideWeather: !prevState.hideWeather
                                      })) }
                            />
                        }
                    </TrackerSettingsContainer>
                </div>
                <TrackerContainer>
                    <Map
                        zoomControl={false} center={mapPosition}
                        zoom={this.state.zoom} minZoom={3} maxZoom={18} onZoomEnd={this._updateZoom}
                        onMoveEnd={this._setCustomPosition}
                        fadeAnimation={true} attributionControl={false}
                        style={{minHeight: "300px", width: "100%"}}

                    >
                        <TileLayer url={this.props.configuration.hubSettings.osmServiceUrl}/>
                        <ZoomControl position='topright'/>
                        <Marker icon={icon} position={iconPosition}/>
                    </Map>
                    { ( weather && !this.state.hideWeather ) &&
                        <TrackerWeather weatherData={weather}/>
                    }
                </TrackerContainer>
                {this._isHeadingUnknown() && this._isCourseUnknown() &&
                <div>
                    Showing approximate location.
                </div>
                }
            </div>
        )
    };
}

Tracker.contextTypes = {
    t: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {configuration: state.configuration};
};

export default connect(mapStateToProps, {fetchFromHub})(Tracker);
import moment from "moment/moment";
import 'moment/locale/de';
import 'moment/locale/en-gb';
import {getLocale} from '../translations/translationUtils';

const localizedFormats = {
    "en": {
        "default": "DD/MM/YYYY",
        "date_dmy": "DD/MM/YYYY",
        "date_as_text_short": "ddd D MMM",
        "date_day_text_day_month": "dddd, DD MMMM",
        "date_month_as_text": "DD MMMM YYYY",
        "date_as_text_full": "dddd Do MMMM YYYY",
        "time": "HH:mm",
        "date_month_short_no_year": "MMM DD",
    },
    "de": {
        "default": "DD.MM.YYYY",
        "date_dmy": "DD.MM.YYYY",
        "date_as_text_short": "ddd D MMM",
        "date_day_text_day_month": "dddd, DD MMMM",
        "date_month_as_text": "DD MMMM YYYY",
        "date_as_text_full": "dddd, Do MMMM YYYY",
        "time": "HH:mm",
        "date_month_short_no_year": "MMM DD",
    },
};

/* generic date/time conversion function that should be used for any one off conversions  */
export function convertDateTime(dateTimeString, inputFormat, outputFormatName, defaultValue = '') {
    if(!dateTimeString) {
        return defaultValue;
    }

    return formatLocalizedMoment(parseToLocalizedMoment(dateTimeString, inputFormat), outputFormatName)
}


function parseToLocalizedMoment(dateTimeString, format = 'DD/MM/YYYY') {
    return moment(dateTimeString, format).locale(getLocale());
}

function getLocalizedFormat(format) {
    const lang = getLocale();
    if ( localizedFormats[lang] && localizedFormats[lang][format] ) {
        return localizedFormats[lang][format];
    }

    if ( localizedFormats[lang] && localizedFormats[lang]['default'] ) {
        return localizedFormats[lang]['default'];
    }

    return "DD/MM/YYYY";
}

function formatLocalizedMoment(dateTimeMoment, formatName = 'date_dmy') {
    return dateTimeMoment.format(getLocalizedFormat(formatName))
}



/* localized common conversions used in multiple places */
export function formatBasketDate(dateTimeString) {
    return convertDateTime(dateTimeString, 'DD/MM/YYYY', 'date_as_text_short');
}

export function formatPortDate(dateTimeString) {
    return convertDateTime(dateTimeString, 'DD/MM/YYYY', 'date_as_text_full');
}

export function formatPortTime(dateTimeString) {
    return convertDateTime(dateTimeString, 'DD/MM/YYYY HH:mm', 'time');
}

export function formatItineraryDate(dateTimeString) {
    return convertDateTime(dateTimeString, 'DD/MM/YYYY', 'date_month_as_text');
}

export function formatCalendarDate(dateTimeString) {
    return convertDateTime(dateTimeString, 'YYYY-MM-DD', 'date_month_short_no_year');
}

export function formatYmdToFullCalendarDate(dateTimeString) {
    return convertDateTime(dateTimeString, 'YYYY-MM-DD', 'date_as_text_full');
}

export function formatMusementExcursionDate(dateTimeString) {
    return convertDateTime(dateTimeString, 'YYYY-MM-DD HH:mm', 'date_as_text_short');
}

export function formatMusementExcursionTime(dateTimeString) {
    return convertDateTime(dateTimeString, 'YYYY-MM-DD HH:mm', 'time');
}

export function formatTimestampToShortText(dateTimeString) {
    return convertDateTime(dateTimeString, 'YYYY-MM-DDTHH:mm', 'date_day_text_day_month')
}


/* NOT localized because used to convert dates to use with the hub */
export function formatDMYtoYMD(date) {
    if (!date) {
        return "";
    }

    return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
}

export function formatYMDtoDMY(date) {
    if (!date) {
        return "";
    }

    return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
}

/* other converters and helpers */
export function formatDuration(durationInMinutes) {
    const hourText = durationInMinutes === 60 ? " Hour" : " Hours";
    return +(durationInMinutes / 60).toFixed(2) + hourText;
}

export function currentlyFlyingBack(flights) {
    let currentlyFlyingBack = false;

    if(flights && flights.length) {
        for (const flight of flights) {
            if(flightDateMatchesCurrentDate(flight.departureTime) || flightDateMatchesCurrentDate(flight.arrivalTime)) {
                currentlyFlyingBack = true;
                break;
            }
        }
    }

    return currentlyFlyingBack;
}

function flightDateMatchesCurrentDate(flightDate) {
    if (!flightDate) {
        return false;
    }

    return moment(flightDate, 'DD/MM/YYYY hh:mm').isSame(moment(), 'day');
}

//helper to sort arrays of objects that have a date property in format dd/mm/yyyy
export function dateSort(a, b) {
    let aa = a.date.split('/').reverse().join();
    let  bb = b.date.split('/').reverse().join();

    return aa < bb ? -1 : (aa > bb ? 1 : 0);
}

// TODO change this if we need to support also other units as just hours and minutes
export function formatIso8601Duration(duration) {
    const momentDuration = moment.duration(duration);

    const hours = momentDuration.hours();
    const minutes = momentDuration.minutes();

    const hoursText =  hours ? hours + " Hour" + (hours > 1 ? "s" : "") : "";
    const minutesText = minutes ? minutes + " Minute" + (minutes > 1 ? "s" : "") : "";
    return `${hoursText} ${minutesText}`;

}

export function formatIso8601MinMaxDuration(durationObject) {
    if(!durationObject || (!durationObject.min && !durationObject.max)) {
        return "";
    }

    const min = durationObject.min ? `"Min: ${formatIso8601Duration(durationObject.min)} ` : "";
    const max = durationObject.max ? `Max: ${formatIso8601Duration(durationObject.max)}` : "";

    return `${min}${max}`;
}

export function getLastMondayDate() {
    return moment().startOf('isoWeek');
}

export function isDateInPast(dateText) {
    return moment(dateText, 'YYYY-MM-DD').isBefore(moment(), 'day');
}

// Accepts "DD/MM/YYYY" as input 
export function isDateTodayOrFuture(dateText) {
    const inputDate = moment(dateText, 'DD/MM/YYYY');
    return inputDate.isSameOrAfter(moment(), 'day');
}

export function formatShortDayOfWeek(dayIndex) {
    return moment().day(dayIndex).locale(getLocale()).format("dd");
}

export function getNrNightsDiff(start, end) {
    return moment(end, "DD/MM/YYYY").diff(moment(start, "DD/MM/YYYY"),'days');
}

export function convertDateForRest(date) {
    const momentDate = moment(date, "DD/MM/YYYY");
    const restDate = momentDate.format("YYYY-MM-DD");
    console.log("convertedDate: " + restDate);
    return restDate;
}

export function convertToLongDate(dateAsString) {
    if (!dateAsString) {
        return "";
    }

    const momentDate = moment(dateAsString, "DD/MM/YYYY");
    return momentDate.format("dddd, DD MMMM");
}

function formatSparkTime(time) {
    if(!time) {
        return "";
    }

    return `${time.hour.toString().padStart(2, '0')}:${time.minute.toString().padStart(2, '0')}`
}

export function formatSparkFromToTime(event) {
    const startTime = event.altStartTimeString || formatSparkTime(event.startTime);
    const endTime = event.altEndTimeString || formatSparkTime(event.endTime);
    return `${startTime} ${endTime && ` - ${endTime}`}`;
}

export function getDayFromDate(date) {
    const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const inputDate = new Date(date);
    const dayIndex = inputDate.getDay();
    return daysOfWeek[dayIndex];
}

// converts "DD/MM/YYYY" to short form of day as "Su" or "Mo" 
export function getShortDayOfWeek(dateString) {
    const date = moment(dateString, "DD/MM/YYYY");
    return date.locale(getLocale()).format("dd");
}

// Formats the date from "DD/MM/YYYY" to "Saturday, 23/05/2021"
export function convertToFormattedDate(dateString) {
    const date = moment(dateString, "DD/MM/YYYY");
    return date.format("dddd, DD.MM.YYYY");
}

// Format HH:MM 24 hour time string to 12 hour + AM/PM
export function get12HourFormattedTime(timeString) {
    const [hours, minutes] = timeString.split(":");
    return new Date(0, 0, 0, Number(hours), Number(minutes)).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
    });
}
import React, {useEffect, useState} from "react";
import DateButton from "../../components/DateButton";
import {fetchFromHub} from "../../actions/networkActions";
import {PropTypes} from "prop-types";
import {useDispatch} from "react-redux";
import OverviewImageButtonPanel from "../../components/OverviewImageButtonPanel";
import Loading from "../../components/Loading";
import {createSubtitle} from "../../utils/itineraryUtils";
import {getTranslation} from "../../translations/translationUtils";

const Overview = () => {
    const dispatch = useDispatch();
    const [schedules, setSchedules] = useState(null);

    useEffect(() => {
        dispatch(fetchFromHub(`itinerary/schedules`, (data) => setSchedules(data)));
    }, [dispatch]);

    if (schedules === null) {
        return (
            <Loading/>
        )
    }

    return (
        <div>
            <OverviewImageButtonPanel>
                {
                    schedules.map((day, index) =>
                        <div key={day.id}
                             className="cell"
                        >
                            <DateButton link={"/itinerary/detail"}
                                        linkId={day.id}
                                        scheduleId={day.id}
                                        date={day.date}
                                        disabled={day.atSea}
                                        index={index}
                                        title={getTranslation(day.title) || day.title.defaultValue}
                                        subtitle={createSubtitle(day)}
                                        thumbnail={day.image}
                            />
                        </div>
                    )}
            </OverviewImageButtonPanel>
        </div>
    );
};

Overview.contextTypes = {
    t: PropTypes.func.isRequired
};

export default Overview;
